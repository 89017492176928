﻿$breakpoint-mobile: 375px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-sm: 1024px;
$breakpoint-desktop-md: 1440px;
$breakpoint-desktop-lg: 1920px;


$nav-color: #0A0B0D;
$tooltip-color: #004CCC;
$nav-highlight: #0078FF;

#site-navbar-wrap {
    .navbar meta-nav {
        display: block;
        height: auto;
        line-height: 0;

        .mobile-dropdown-toggle {
            position: absolute;
            top: 12px;
            right: 20px;
            display: none;

            @media(max-width: $breakpoint-desktop-sm - 1) {
                &.nav-opened {
                    display: block;
                }
            }

            @media (min-width: $breakpoint-tablet) {
                top: 24px;
            }

            .icon-holder {
                background-image: url(../../img/dropdown-white.svg);
                width: 28px;
                height: 28px;
                display: block;

                &.expanded {
                    background-image: url(../../img/close-white.svg);
                }
            }
        }

        .meta-nav-container {
            display: inline-block;

            @media(max-width: $breakpoint-desktop-sm - 1) {
                left: -1px;
                width: calc(100% + 2px);
                background: #0A0B0D;
                height: 60px;
                transition: height 0.2s;
            }

            @media(min-width: $breakpoint-desktop-sm) {
                bottom: 0px;
                right: 0px;
                padding-right: 48px;
                position: absolute;
            }

            &.no-animate {
                @media(max-width: $breakpoint-desktop-sm - 1) {
                    transition: none;
                }

                .sidenav-right {
                    transition: none;
                }
            }

            .sidenav-backdrop {
                display: none;
                width: 100%;
                position: absolute;
                height: 100vh;
                right: 0px;
                background-color: rgba(33,33,33,1.0);
                opacity: .48;

                @media(max-width: 460px) {
                    display: block;
                    width: 200vw;
                    top: 120px;
                }

                @media(min-width: $breakpoint-desktop-sm) {
                    display: block;
                    width: 200vw;
                }
            }

            .sidenav-right {
                left: -1vw;
                top: calc(100% + 1px);
                background: white;
                position: absolute;
                height: 0px;
                width: 100%;
                -webkit-transition-duration: 400ms;
                transition-duration: 400ms;
                transition-property: height, transform;
                z-index: 1;
                overflow-y: auto;

                @media(min-width: $breakpoint-tablet) {
                    width: 100vw;
                }

                @media(min-width: $breakpoint-desktop-sm) {
                    left: calc(100% + 1px);
                    height: 100vh;
                    width: 456px;
                    top: unset;
                }

                &.open {
                    @media(max-width: $breakpoint-desktop-sm - 1) {
                        height: 100vh;
                        width: 101vw;

                        .content, .loader-container {
                            visibility: visible;
                            transition: visibility 0s 0.2s;
                        }
                    }

                    @media(min-width: $breakpoint-desktop-sm) {
                        transform: translate(-100%, 0);
                    }
                }

                .sidenav-close {
                    display: none;

                    &:focus {
                        outline: auto;
                    }

                    @media(min-width: $breakpoint-desktop-sm) {
                        display: block;
                    }
                }

                .loader-container {
                    top: 25%;
                }

                .loader-spinner {
                    border-right-color: #004ccc;
                }

                .content {
                    margin-top: 32px;
                    margin-bottom: 24px;
                    text-align: left;
                    width: 100%;
                    position: absolute;

                    @media(min-width: $breakpoint-desktop-sm) {
                        margin-top: 16px;
                        top: 50px;
                    }

                    @media(min-width: $breakpoint-desktop-md) {
                        margin-top: 24px;
                    }

                    @media(min-width: $breakpoint-desktop-lg) {
                        margin-top: 36px;
                        top: 80px;
                    }
                }

                @media(max-width: $breakpoint-desktop-sm - 1) {
                    .content, .loader-container {
                        visibility: hidden;
                        transition: visibility 0s;
                    }
                }

                .blue-text {
                    background: #004ccc;
                    color: #fff;
                }

                #meta-nav-drawer--account-widget {
                    text-align: center;

                    .logged-out-content {
                        .deep-link-message {
                            font-weight: bold;
                            font-size: 22px;
                            text-align: left;
                            line-height: 1.4;
                            margin: 0 20px 32px;
                            font-family: 'MarkOTBold';

                            @media (min-width: $breakpoint-tablet) {
                                margin: 16px 162px 40px;
                                font-size: 28px;
                            }

                            @media (min-width: $breakpoint-desktop-sm) {
                                margin: -16px 40px 16px;
                                font-size: 20px;
                            }

                            @media (min-width: $breakpoint-desktop-md) {
                                margin: -8px 48px 24px;
                                font-size: 28px;
                            }

                            @media (min-width: $breakpoint-desktop-lg) {
                                margin: 4px 64px 48px;
                                font-size: 36px;
                            }
                        }
                    }

                    .reward-text {
                        color: #0a0b0d;

                        @media (min-width: $breakpoint-mobile) {
                            font-size: 18px;
                            line-height: 1.33;
                            font-weight: 500;
                            padding-top: 48px;
                        }

                        @media (min-width: $breakpoint-tablet) {
                            font-size: 22px;
                            line-height: 1.45;
                            font-weight: 500;
                            padding-top: 48px;
                        }

                        @media (min-width: $breakpoint-desktop-sm) {
                            font-size: 20px;
                            line-height: 1.4;
                            font-weight: bold;
                            padding-top: 48px;
                        }

                        @media (min-width: $breakpoint-desktop-lg) {
                            font-size: 24px;
                            line-height: 1.33;
                            font-weight: 500;
                            padding-top: 64px;
                        }
                    }

                    .scene-logo {
                        width: 310px;
                        height: 72px;

                        @media (min-width: $breakpoint-desktop-sm) {
                            width: 440px;
                            height: 90px;
                            padding: 0px 48px;
                        }

                        @media (min-width: $breakpoint-desktop-lg) {
                            width: 528px;
                            height: 90px;
                            padding: 0px;
                        }
                    }

                    .logged-in-content {
                        font-size: 16px;
                        line-height: 1.43;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        letter-spacing: normal;
                        text-align: center;

                        @media (min-width: $breakpoint-tablet) {
                            line-height: 1.5;
                        }

                        @media (min-width: $breakpoint-desktop-sm) {
                            font-size: 14px;
                            line-height: 1.2;
                            text-align: center;
                        }

                        @media (min-width: $breakpoint-desktop-md) {
                            font-size: 20px;
                            line-height: 28px;
                        }

                        .profile-picture {
                            height: 80px;
                            width: 80px;
                            border-radius: 50%;
                            margin: 0 auto;
                            overflow: hidden;
                            position: relative;

                            @media (min-width: $breakpoint-desktop-sm) {
                                height: 104px;
                                width: 104px;
                            }

                            @media (min-width: $breakpoint-desktop-md) {
                                height: 96px;
                                width: 96px;
                            }

                            @media (min-width: $breakpoint-desktop-lg) {
                                height: 120px;
                                width: 120px;
                            }

                            .initials {
                                line-height: 83px;
                                font-size: 30px;
                                font-weight: 600;

                                @media (min-width: $breakpoint-desktop-sm) {
                                    line-height: 104px;
                                    font-size: 28px;
                                    text-align: center;
                                }

                                @media (min-width: $breakpoint-desktop-md) {
                                    line-height: 102px;
                                }

                                @media (min-width: $breakpoint-desktop-lg) {
                                    line-height: 120px;
                                    font-size: 34px;
                                }
                            }

                            img {
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                margin: auto;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .profile-name {
                            font-size: 26px;
                            font-weight: 600;
                            text-transform: uppercase;
                            line-height: 1.45;
                            padding-top: 8px;

                            @media(min-width: $breakpoint-tablet) {
                                font-size: 32px;
                                padding-top: 12px;
                            }

                            @media (min-width: $breakpoint-desktop-sm) {
                                font-size: 22px;
                                line-height: 40px;
                                width: 100%;
                                margin: 0px;
                                text-align: center;
                            }

                            @media (min-width: $breakpoint-desktop-md) {
                                font-size: 30px;
                                line-height: 28px;
                            }

                            @media (min-width: $breakpoint-desktop-lg) {
                                font-size: 36px;
                                line-height: 50px;
                            }
                        }

                        .manage-account {
                            margin-top: 16px;
                            display: block;
                            height: 24px;
                            font-size: 16px;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.43;
                            letter-spacing: normal;
                            color: #004ccc;

                            @media (min-width: $breakpoint-tablet) {
                                line-height: 1.5;
                            }

                            @media (min-width: $breakpoint-desktop-sm) {
                                line-height: 1.33;
                                height: 16px;
                                font-size: 13px;
                            }

                            @media (min-width: $breakpoint-desktop-md) {
                                margin-top: 24px;
                                line-height: 1.43;
                                height: 24px;
                                font-size: 16px;
                            }

                            @media (min-width: $breakpoint-desktop-lg) {
                                margin-top: 16px;
                                line-height: 1.4;
                                height: 28px;
                                font-size: 20px;
                            }

                            &:hover, &:focus {
                                //copied style from bootstrap css, due to Agility pages css affecting <a>
                                color: #013874;
                                text-decoration: underline;
                            }
                        }

                        .logout {
                            margin-top: 32px;

                            @media (min-width: $breakpoint-desktop-sm) {
                                margin-top: 24px;
                            }

                            @media (min-width: $breakpoint-desktop-md) {
                                margin-top: 8px;
                            }

                            @media (min-width: $breakpoint-desktop-lg) {
                                margin-top: 48px;
                            }
                        }
                    }

                    .login, .logout {
                        display: block;
                        height: 36px;
                        width: 310px;
                        line-height: 38px;
                        margin: 0 auto;
                        font-size: 14px;
                        font-weight: 600;
                        text-transform: uppercase;
                        letter-spacing: normal;
                        font-stretch: normal;
                        font-style: normal;

                        @media(min-width:$breakpoint-tablet) {
                            width: 180px;
                            line-height: 14px;
                            font-size: 14px;
                            letter-spacing: .2px;
                        }

                        @media(min-width: $breakpoint-desktop-sm) {
                            width: 175px;
                            line-height: 18px;
                            font-size: 12.6px;
                            letter-spacing: .18px;
                        }

                        @media(min-width: $breakpoint-desktop-md) {
                            height: 40px;
                            width: 194px;
                            line-height: 20px;
                            font-size: 14px;
                            letter-spacing: .17px;
                        }

                        @media(min-width: $breakpoint-desktop-lg) {
                            height: 48px;
                            width: 256px;
                            line-height: 48px;
                            font-size: 16px;
                            letter-spacing: .2px;
                        }
                    }

                    .sign-up-content {
                        font-size: 20px;
                        margin-top: 32px;

                        p {
                            display: inline-block;
                        }
                    }

                    a {
                        padding: 0;
                        font-weight: 600;
                        font-size: 20px;
                        color: $nav-highlight;
                        text-decoration: none;

                        &:hover, &:focus {
                            color: $tooltip-color;
                            text-decoration: underline;
                        }
                    }
                }
            }

            .nav-buttons-container {
                @media(max-width: $breakpoint-desktop-sm - 1) {
                    height: auto;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    bottom: -1px;
                    transition: visibility 0s 100ms;
                }
            }

            .nav-button {
                padding: 0;
                color: #fff;
                height: 100%;
                align-items: center;
                outline: none;

                @media(max-width: $breakpoint-desktop-sm - 1) {
                    width: 25%;
                    float: left;
                    justify-content: center;
                }

                @media(min-width: $breakpoint-desktop-sm) {
                    margin-left: 16px;
                }


                &:first-child {
                    margin-left: 0;
                }

                p {
                    margin: 0;
                    line-height: 0;
                    height: 100%;

                    @media(max-width: $breakpoint-desktop-sm - 1) {
                        margin-bottom: 6px;
                    }

                    .icon-holder {
                        height: 24px;
                        min-width: 24px;
                        display: inline-flex;
                        background-size: cover;

                        &.ticket {
                            background-image: url(../../img/ticket-white.svg);
                        }

                        &.location {
                            background-image: url(../../img/location-white.svg);
                        }

                        &.search {
                            background-image: url(../../img/search-white.svg);
                        }

                        &.user-profile {
                            background-image: url(../../img/profile-white.svg);
                        }

                        @media (min-width: $breakpoint-desktop-sm) {
                            height: 100%;
                            min-width: 20px;
                        }
                    }

                    .text-holder {
                        align-items: center;
                        text-transform: uppercase;
                        font-size: 10px;
                        display: block;
                        margin-top: 4px;
                        line-height: 16px;
                        letter-spacing: 0.01em;

                        @media(min-width: $breakpoint-desktop-sm) {
                            display: inline-flex;
                            position: relative;
                            margin-left: 6px;
                            bottom: 6px;
                            font-size: 10px;
                        }
                    }
                }

                &:hover, &.selected, &:focus {
                    color: $nav-highlight;
                    font-weight: 400;

                    @media (max-width: $breakpoint-desktop-sm - 1) {
                        border-bottom: $nav-highlight solid 3px;

                        p {
                            margin-bottom: 3px;
                        }
                    }

                    .icon-holder {
                        display: inline-block;

                        &.ticket {
                            background-image: url(../../img/ticket-blue.svg);
                        }

                        &.location {
                            background-image: url(../../img/location-blue.svg);
                        }

                        &.search {
                            background-image: url(../../img/search-blue.svg);
                        }

                        &.user-profile {
                            background-image: url(../../img/profile-blue.svg);
                        }
                    }
                }

                &.sidenav-close {
                    position: absolute;
                    top: 24px;
                    right: 44px;
                    height: 27px;
                    width: 27px;
                    margin-top: 0;
                    background-size: cover;
                    background-image: url(../../img/close-blue.svg);
                    background-repeat: no-repeat;

                    @media(min-width: $breakpoint-desktop-sm) {
                        right: 48px;
                        top: 24px;
                        height: 24px;
                        width: 24px;
                        background-size: 24px;
                    }
                }
            }
        }

        p, span, div, a {
            font-family: "FFMarkWebPro", "Helvetica Neue";
        }
        /*preload hover images to avoid blinking*/
        &::after {
            position: absolute;
            visibility: hidden;
            left: -9999px;
            content: url(../../img/close-white.svg) url(../../img/ticket-blue.svg) url(../../img/location-blue.svg) url(../../img/search-blue.svg) url(../../img/profile-blue.svg) url(../../img/close-blue.svg);
        }
    }

    &.french-menu {
        &.responsive-desktop-default {
            .sidenav-right {
                width: 676px !important;
            }
        }

        &.responsive-desktop-large {
            .sidenav-right {
                width: 550px !important;
            }
        }

        &.responsive-desktop-medium {
            .sidenav-right {
                width: 470px !important;
            }
        }

        &.responsive-desktop-small {
            .sidenav-right {
                width: 470px !important;
            }
        }

        .navbar {
            meta-nav .meta-nav-container {
                @media (min-width: $breakpoint-desktop-sm) {
                    padding-right: 40px;
                }

                .nav-button {
                    @media (min-width:$breakpoint-desktop-sm) {
                        padding-bottom: 1px;
                    }
                }

                nav-buttons-container {
                    .tooltip {
                        @media(min-width: $breakpoint-tablet) {
                            display: block;
                        }
                    }

                    .nav-button {
                        @media(min-width: $breakpoint-desktop-sm) {
                            margin-left: 10px;
                        }

                        @media(min-width: $breakpoint-desktop-lg) {
                            margin-left: 32px;
                        }
                    }
                }
            }
        }
    }

    &.fixed-scroll .navbar meta-nav {
        @media(max-width: $breakpoint-desktop-sm - 1) {
            .mobile-dropdown-toggle {
                display: block;
            }

            .meta-nav-container {
                height: 0;

                .nav-buttons-container {
                    visibility: hidden;
                    transition: visibility 0s;
                }

                &.mobile-expanded {
                    height: 63px;

                    .nav-buttons-container {
                        visibility: visible;
                        transition: visibility 0s .1s;
                    }
                }
            }
        }
    }

    @media(min-width: $breakpoint-desktop-sm) {
        &.responsive-desktop-default {
            .navbar meta-nav {
                .meta-nav-container {
                    padding-right: 64px;

                    .sidenav-right {
                        top: 100%;
                        background: white;
                        position: absolute;
                        left: calc(100% + 1px);
                        height: 100vh;
                        -webkit-transition-duration: 400ms;
                        transition-duration: 400ms;
                        transition-property: height, transform;
                        z-index: 1;
                        width: 656px;
                        overflow-y: auto;

                        .content {
                            margin-top: 32px;
                            margin-bottom: 24px;
                            text-align: left;
                            width: 100%;
                            position: absolute;
                        }
                    }

                    .nav-buttons-container {
                        padding-bottom: 24px;

                        .nav-button {
                            padding: 0;
                            height: 32px;
                            margin-left: 32px;

                            &:first-child {
                                margin-left: 0;
                            }

                            &:hover, &.selected {
                                color: $nav-highlight;
                                font-weight: 400;
                            }

                            p {
                                .icon-holder {
                                    min-width: 32px;
                                }

                                .text-holder {
                                    align-items: center;
                                    text-transform: uppercase;
                                    font-size: 14px;
                                    bottom: 11px;
                                    display: inline-flex;
                                    position: relative;
                                    margin-left: 6px;
                                    margin-top: 4px;
                                    line-height: 16px;
                                    letter-spacing: 0.01em;
                                }
                            }
                        }
                    }

                    .nav-button {
                        &.sidenav-close {
                            position: absolute;
                            margin-top: 0;
                            background-image: url(../../img/close-blue.svg);
                            top: 48px;
                            right: 64px;
                            height: 36px;
                            width: 36px;
                            background-size: 36px;
                        }
                    }
                }
            }
        }

        &.responsive-desktop-large {
            .navbar {
                meta-nav {
                    .meta-nav-container {
                        padding-right: 48px;

                        .sidenav-right {
                            width: 528px;
                        }

                        .nav-buttons-container {
                            padding-bottom: 20px;

                            .nav-button {
                                margin-bottom: 0px;
                                margin-left: 24px;
                                height: 24px;

                                p {
                                    .icon-holder {
                                        margin-bottom: 2px;
                                        min-width: 24px;
                                    }

                                    .text-holder {
                                        font-size: 12px;
                                        bottom: 10px;
                                    }
                                }
                            }
                        }

                        .nav-button {
                            &.sidenav-close {
                                position: absolute;
                                margin-top: 0;
                                top: 32px;
                                right: 48px;
                                height: 28px;
                                width: 28px;
                                background-size: 28px;
                            }
                        }
                    }
                }
            }
        }

        &.responsive-desktop-medium {
            .navbar {
                meta-nav {
                    .meta-nav-container {
                        padding-right: 48px;

                        .sidenav-right {
                            z-index: -1;
                            width: 456px;
                        }

                        .sidenav-backdrop {
                            z-index: -1;
                        }

                        .nav-buttons-container {
                            padding-bottom: 16px;

                            .nav-button {
                                margin-bottom: 0px;
                                margin-left: 16px;
                                height: 20px;

                                p {
                                    .icon-holder {
                                        min-width: 20px;
                                    }

                                    .text-holder {
                                        bottom: 7px;
                                        font-size: 10px;
                                    }
                                }
                            }
                        }

                        .nav-button {
                            &.sidenav-close {
                                position: absolute;
                                margin-top: 0;
                                right: 48px;
                                top: 24px;
                                height: 24px;
                                width: 24px;
                                background-size: 24px;
                            }
                        }
                    }
                }
            }
        }

        &.responsive-desktop-small {
            .navbar {
                meta-nav {
                    .meta-nav-container {
                        .nav-buttons-container {
                            padding-bottom: 18px;

                            .nav-button {
                                height: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}