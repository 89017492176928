@use 'meta-nav';

.get-tickets {

    @media (max-width: (meta-nav.$breakpoint-tablet - 1)) {
        padding-left: 0;
        padding-right: 0;
    }

    @media (min-width: meta-nav.$breakpoint-tablet) {
        padding-left: 15px;
    }
}

.btn-gettickets {
    background: #004ccc;
    border: #004ccc;
    color: #FFF;
    display: inline-block;
    text-align: center;
    padding: 8px;
    width: 172px;
    height: 48px;
    line-height: 24px;
    font-size: 16px;
    text-decoration: none !important;

    &:hover, &:focus {
        color: #fff !important;
    }

    @media (max-width: (meta-nav.$breakpoint-tablet - 1)) {
        width: 100%;
        height: 36px;
        margin-top: 30px;
    }

    @media (min-width: meta-nav.$breakpoint-tablet) {
        width: 180px;
        height: 36px;
        line-height: 20px;
        font-size: 14px;
        padding: 9px 0px;
        margin-top: 0px;
    }
}



