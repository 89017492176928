/*Imported Bootstrap Variables*/
/*--------------- FONT MODIFICATIONS --------------------*/
/*--------- BUTTON MODIFICATIONS --------------*/
/*-----------------MEDIA QUERY MODIFICATIONS-----------*/
/*-----------------GRID SYSTEM MODIFICATIONS-------------*/
/*-----------------CONTAINER MODIFICATIONS-------------*/
/*(720px + @grid-gutter-width);*/
/*(940px + @grid-gutter-width);*/
/*-----------------NAVBAR MODIFICATIONS----------------*/
/*VARIABLES*/
/*Site Variables*/
/*------------GENERAL STYLES---------------*/
/*Sizing*/
/*Colours*/
/*Fonts*/
/*Borders*/
/*Loader Styles*/
/*Showtimes Styles*/
/*Selectize Widget Styles*/
/*--------------COMPONENT STYLES---------*/
/*Search Component*/
/*Movies Grid Components*/
/*Quick Tickets Component*/
/*--------------PAGE SPECIFIC STYLES --------------*/
/*--------THEATRE DETAILS----------*/
/*---------MOVIE DETAILS----------*/
/*--------------LAYOUT STYLES------------*/
/*SKIN*/
/*Navbar*/
/*Menu*/
/*Footer*/
/*MIXINS*/
/*BASE STYLES*/
body, html {
  background: #FFF;
}
body .container {
  background: #FFF;
}
body footer .container {
  background: #0a0b0d;
}
body .footer-ad-area .container {
  background: #0a0b0d;
}
#IEWarning {
    padding: 20px 18px 10px 18px;
    width: 100%;
    font-size: 15px;
    font-weight: bold;
    font-family: 'FFMarkWebProBold', "Helvetica Neue", helvetica, arial, sans-serif;
    letter-spacing: 0px;
    color: #FFFFFF;
    background-color: #d0021c;
}
img {
    max-width: 100%;
    height: auto;
}
input {
  border: 1px solid #d5d5d5;
  -webkit-border-radius: 0px;
}
ul,
ol {
  padding: 0;
  margin: 0;
}
a {
  cursor: pointer;
}
.top-ad-container,
.main-content.container,
footer,
.footer-ad-area {
  position: relative;
}
.main-content.container {
  position: relative;
  padding-top: 20px;
}
.icon-arrow-down {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-arrow-down:before {
  content: "\f078";
}
@font-face {
    font-family: 'cineplex-custom-icon';
    src: url('../../fonts/cineplex-custom-icons-2017.eot?nc2pkw');
    src: url('../../fonts/cineplex-custom-icons-2017.eot?nc2pkw#iefix') format('embedded-opentype'), url('../../fonts/cineplex-custom-icons-2017.ttf?nc2pkw') format('truetype'), url('../../fonts/cineplex-custom-icons-2017.woff?nc2pkw') format('woff'), url('../../fonts/cineplex-custom-icons-2017.svg??nc2pkw#cineplex-custom-icons-2017') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class^="iconV2-"],
[class*=" iconV2-"] {
  font-family: 'cineplex-custom-icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.iconV2-valet-parking:before {
  content: "\61";
}
.iconV2-theatre-seat:before {
  content: "\62";
}
.iconV2-hearing-assistance:before {
  content: "\e600";
}
.iconV2-descriptive-video:before {
  content: "\e601";
}
.iconV2-cc:before {
  content: "\e602";
}
.iconV2-info:before {
  content: "\e900";
}
.fa.fa-cc {
  font-family: 'cineplex-custom-icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa.fa-cc::before,
.fa.fa-cc:before {
  content: "\e602";
}
/*OVERALL STYLES*/
.body-skin-css-class {
  /*@media(min-width: @skin-visible-width) {
        margin-top: 105px;
    }*/
}
@media (min-width: 768px) {
  .body-skin-css-class .main-content.container {
    margin-bottom: 100px;
  }
}
.analytics-element {
    display: none;
}
.text-underline {
  text-decoration: underline;
}
.no-text-decoration {
  text-decoration: none;
}
.no-text-decoration:hover,
.no-text-decoration:focus {
  text-decoration: none;
}
.text-bold {
  font-weight: bold;
}
.text-weight-light {
  font-weight: 400;
}
.text-weight-heavy {
  font-weight: 700;
}
.prevent-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}
.no-wrap {
  white-space: nowrap;
  text-wrap: none;
}
.text-white {
  color: #FFF;
}
.text-lighter-grey {
  color: #d5d5d5;
}
.text-light-grey {
  color: #909090;
}
.text-grey {
  color: #4D4D4D;
}
.text-dark-grey {
  color: #333;
}
.text-darker-grey {
  color: #0d0d0d;
}
.text-light-blue {
  color: #71C5E8;
}
.text-highlight {
  color: #004ea8;
}
.text-tertiary {
  color: #ff671f;
}
.btn-tertiary {
  background: #ff671f;
  border: #ff671f;
  color: #FFF;
}
.btn-tertiary:hover,
.btn-tertiary:focus {
  background: #9f3300;
  border-color: #9f3300;
  color: #FFF;
}

.font-xs {
  font-size: 0.571428571em;
}
.font-sm {
  font-size: 0.857142857em;
}
.font-md {
  font-size: 1em;
}
.font-lg {
  font-size: 1.14285714286em;
}
.font-xl {
  font-size: 1.2857142857em;
}
.font-xxl {
  font-size: 4em;
}
.bg-off-white {
  background: #F5F5F5;
}
.bg-light-grey {
  background: #909090;
}
.col-xs-2point4 {
  float: left;
  width: 20%;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .col-sm-2point4 {
    float: left;
    width: 20%;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 992px) {
  .col-md-2point4 {
    float: left;
    width: 20%;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 1082px) {
  .col-lg-2point4 {
    float: left;
    width: 20%;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.fill {
  width: 100%;
  height: 100%;
}
.fill-width {
  width: 100%;
}
.fill-height {
  height: 100%;
}
.max-width-fill {
  max-width: 100%;
}
.max-height-fill {
  max-height: 100%;
}
.content-width-fill > * {
  width: 100%;
}
.content-max-width-fill > * {
  max-width: 100%;
}
content-height-fill > * {
  height: 100%;
}
.content-max-height-fill > * {
  max-height: 100%;
}
.overflow-hidden {
  overflow: hidden;
}
.clear {
  clear: both;
}
.no-pad {
  padding: 0px;
}
.pad-xs {
  padding: 5px;
}
.pad-sm {
  padding: 10px;
}
.pad-md {
  padding: 20px;
}
.pad-lg {
  padding: 30px;
}
.pad-vertical-xs {
  padding-top: 5px;
  padding-bottom: 5px;
}
.pad-vertical-sm {
  padding-top: 10px;
  padding-bottom: 10px;
}
.pad-vertical-md {
  padding-top: 20px;
  padding-bottom: 20px;
}
.pad-vertical-lg {
  padding-top: 30px;
  padding-bottom: 30px;
}
.pad-horizontal-xs {
  padding-left: 5px;
  padding-right: 5px;
}
.pad-horizontal-sm {
  padding-left: 10px;
  padding-right: 10px;
}
.pad-horizontal-md {
  padding-left: 20px;
  padding-right: 20px;
}
.pad-horizontal-lg {
  padding-left: 30px;
  padding-right: 30px;
}
.no-pad-bot {
  padding-bottom: 0;
}
.pad-bot-xs {
  padding-bottom: 5px;
}
.pad-bot-sm {
  padding-bottom: 10px;
}
.pad-bot-md {
  padding-bottom: 20px;
}
.pad-bot-lg {
  padding-bottom: 30px;
}
.no-pad-right {
  padding-right: 0;
}
.pad-right-xs {
  padding-right: 5px;
}
.pad-right-sm {
  padding-right: 10px;
}
.pad-right-md {
  padding-right: 20px;
}
.pad-right-lg {
  padding-right: 30px;
}
.no-pad-left {
  padding-left: 0;
}
.pad-left-xs {
  padding-left: 5px;
}
.pad-left-sm {
  padding-left: 10px;
}
.pad-left-md {
  padding-left: 20px;
}
.pad-left-lg {
  padding-left: 30px;
}
.no-pad-top {
  padding-top: 0px;
}
.pad-top-xs {
  padding-top: 5px;
}
.pad-top-sm {
  padding-top: 10px;
}
.pad-top-md {
  padding-top: 20px;
}
.pad-top-lg {
  padding-top: 30px;
}
.no-margin {
  margin: 0px;
}
.margin-xs {
  margin: 5px;
}
.margin-sm {
  margin: 10px;
}
.margin-md {
  margin: 20px;
}
.margin-lg {
  margin: 30px;
}
.margin-vertical-xs {
  margin-top: 5px;
  margin-bottom: 5px;
}
.margin-vertical-sm {
  margin-top: 10px;
  margin-bottom: 10px;
}
.margin-vertical-md {
  margin-top: 20px;
  margin-bottom: 20px;
}
.margin-vertical-lg {
  margin-top: 30px;
  margin-bottom: 30px;
}
.margin-horizontal-xs {
  margin-left: 5px;
  margin-right: 5px;
}
.margin-horizontal-sm {
  margin-left: 10px;
  margin-right: 10px;
}
.margin-horizontal-md {
  margin-left: 20px;
  margin-right: 20px;
}
.margin-horizontal-lg {
  margin-left: 30px;
  margin-right: 30px;
}
.no-margin-bot {
  margin-bottom: 0;
}
.margin-bot-xs {
  margin-bottom: 5px;
}
.margin-bot-sm {
  margin-bottom: 10px;
}
.margin-bot-md {
  margin-bottom: 20px;
}
.margin-bot-lg {
  margin-bottom: 30px;
}
.no-margin-right {
  marign-right: 0;
}
.margin-right-xs {
  margin-right: 5px;
}
.margin-right-sm {
  margin-right: 10px;
}
.margin-right-md {
  margin-right: 20px;
}
.margin-right-lg {
  margin-right: 30px;
}
.no-margin-left {
  margin-left: 0;
}
.margin-left-xs {
  margin-left: 5px;
}
.margin-left-sm {
  margin-left: 10px;
}
.margin-left-md {
  margin-left: 20px;
}
.margin-left-lg {
  margin-left: 30px;
}
.no-margin-top {
  margin-top: 0;
}
.margin-top-xs {
  margin-top: 5px;
}
.margin-top-sm {
  margin-top: 10px;
}
.margin-top-md {
  margin-top: 20px;
}
.margin-top-lg {
  margin-top: 30px;
}
.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative;
}
.vertical-align-middle {
  vertical-align: middle;
  white-space: nowrap;
}
.vertical-align-middle > * {
  vertical-align: middle;
  white-space: initial;
  display: inline-block;
  float: none;
}
/*.vertical-align-middle:before {
    content: "";
    display: inline-block;
    height: 100%;
    width: 0;
    margin: 0;
    padding: 0;
    vertical-align: middle;
}*/
.vertical-align-bottom {
  vertical-align: bottom;
  /*white-space: nowrap;*/
}
.vertical-align-bottom > * {
  vertical-align: bottom;
  white-space: initial;
  display: inline-block;
}
.vertical-align-top {
  vertical-align: top;
}
.vertical-align-top > * {
  vertical-align: top;
  white-space: initial;
  display: inline-block;
}
/*.vertical-align-bottom:before {
    border-width: 0px;
    content: "";
    display: inline-block;
    height: 100%;
    width: 0;
    margin: 0;
    padding: 0;
    vertical-align: bottom;
}*/
.absolute-align-center {
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.absolute-vertical-align-bottom {
  position: absolute;
  bottom: 0;
}
.absolute-top {
  top: 0;
}
.absolute-bottom {
  bottom: 0;
}
.absolute-left {
  left: 0;
}
.absolute-right {
  right: 0;
}
.no-border {
  border: 0px solid #000;
}
.border-light {
  border: 1px solid #D4D4D4;
}
.border-silver {
  border: 1px solid #A7A8AA;
}
.border-left-light {
  border-left: 1px solid #D4D4D4;
}
.border-right-light {
  border-right: 1px solid #D4D4D4;
}
.border-top-light {
  border-top: 1px solid #D4D4D4;
}
.border-bottom-light {
  border-bottom: 1px solid #D4D4D4;
}
.border-medium {
  border: 4px solid #a7a8aa;
}
.border-left-medium {
  border-left: 4px solid #a7a8aa;
}
.border-right-medium {
  border-right: 4px solid #a7a8aa;
}
.border-top-medium {
  border-top: 4px solid #a7a8aa;
}
.border-bottom-medium {
  border-bottom: 4px solid #a7a8aa;
}
.border-heavy {
  border: 5px solid #000;
}
.border-left-heavy {
  border-left: 5px solid #000;
}
.border-right-heavy {
  border-right: 5px solid #000;
}
.border-top-heavy {
  border-top: 5px solid #000;
}
.border-bottom-heavy {
  border-bottom: 5px solid #000;
}
.double-border-bottom {
  border-bottom: 4px double #666;
}
.border-bottom-silver {
  border-bottom: 1px solid #A7A8AA;
}
.border-radius-xs {
  border-radius: 5px;
}
.border-radius-sm {
  border-radius: 5px;
}
.border-radius-md {
  border-radius: 10px;
}
.border-radius-lg {
  border-radius: 20px;
}
.border-radius-top-sm {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.border-radius-top-md {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.border-radius-top-lg {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.border-radius-bot-sm {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.border-radius-bot-md {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.border-radius-bot-lg {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.no-list-style {
  list-style: none;
}
.cursor-default {
  cursor: default;
}
.cursor-pointer {
  cursor: pointer;
}
.inline-block {
  display: inline-block;
}
.block {
  display: block;
}
.inline {
  display: inline;
}
.rollover-image-wrap .rollover-image-container {
  position: relative;
}
.rollover-image-wrap .rollover-image-container .initial-image {
  opacity: 1;
  visibility: visible;
  height: auto;
  width: auto;
}
.rollover-image-wrap .rollover-image-container .rollover-image {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  top: 0;
  left: 0;
  max-width: 100%;
}
.rollover-image-wrap:hover .rollover-image-container,
.rollover-image-wrap:focus .rollover-image-container {
  position: relative;
}
.rollover-image-wrap:hover .rollover-image-container .initial-image,
.rollover-image-wrap:focus .rollover-image-container .initial-image {
  opacity: 0;
  visibility: hidden;
  height: auto;
  width: auto;
}
.rollover-image-wrap:hover .rollover-image-container .rollover-image,
.rollover-image-wrap:focus .rollover-image-container .rollover-image {
  opacity: 1;
  visibility: visible;
}

.movie-img-hover-wrapper {
    position: relative;
    width: 100%;
}

    .movie-img-hover-wrapper .movie-img-hover-text {
        display: none;
        color: #FFF;
        z-index: 3;
        position: absolute;
        text-transform: uppercase;
        text-align: center;
        font-weight: 700;
        font-size: 1.28571em;
        width: 100%;
    }

    .movie-img-hover-wrapper:hover,
    .movie-img-hover-wrapper:focus {
        display: block;
    }

        .movie-img-hover-wrapper:hover:before,
        .movie-img-hover-wrapper:focus:before {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            height: 100%;
            width: 100%;
            z-index: 2;
            background: rgba(0, 0, 0, 0.6);
        }

        .movie-img-hover-wrapper:hover .movie-img-hover-text,
        .movie-img-hover-wrapper:focus .movie-img-hover-text {
            display: block;
        }
/*testing*/
.cineplex-error-message {
  display: none;
  position: absolute;
  top: 100%;
  left: -1px;
  right: -1px;
  background: #d0021b;
  border: #d0021b;
  overflow: visible;
  z-index: 2;
  padding: 10px;
  margin-top: 9px;
  color: #FFF;
  font-weight: 700;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  text-align: left;
  outline: none;
}
.cineplex-error-message .error-top-arrow {
  position: absolute;
  bottom: 100%;
  left: 20%;
  margin-left: -10px;
  border: 10px solid transparent;
  border-bottom: 10px solid #d0021b;
}
.cineplex-error-message .error-top-arrow:before {
  content: "";
  position: absolute;
  top: -9px;
  left: -10px;
  border: 10px solid transparent;
  border-bottom: 10px solid #d0021b;
}
.cineplex-error-notification {
  background: #fe8795;
  display: block;
  position: relative;
}
.cineplex-error-notification .fa {
  font-size: 20px;
  position: absolute;
  color: #d0021b;
  top: 18px;
  left: 10px;
}
.cineplex-error-notification .notification-content {
  padding: 20px 40px;
  color: #000000;
}
.cineplex-warning-notification {
  background: #fbf1cc;
  display: block;
  position: relative;
}
.cineplex-warning-notification .fa {
  font-size: 20px;
  position: absolute;
  color: #ff671f;
  top: 18px;
  left: 10px;
}
.cineplex-warning-notification .notification-content {
  padding: 20px 40px;
}
.cineplex-info-notification {
  background: #e2f7e5;
  display: block;
  position: relative;
}
.cineplex-info-notification .fa {
  font-size: 20px;
  position: absolute;
  color: #004ea8;
  top: 18px;
  left: 10px;
}
.cineplex-info-notification .notification-content {
  padding: 20px 40px;
}
.cta-primary {
  text-transform: uppercase;
  text-decoration: none;
}
.cta-primary i {
  position: relative;
  margin-left: 7px;
  font-size: 1.5625em;
  top: 0.09em;
}
.cta-primary:hover {
  text-decoration: none;
}
.cta-primary:hover i {
  left: 3px;
}
.format-sprite {
    background-image: url(../../img/format_spritesheet_1x.v6.png);
    background-repeat: no-repeat;
    display: inline-block;
    /*Media query to display 2x image*/
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .format-sprite {
        background-image: url(../../img/format_spritesheet_2x.v6.png);
        /*Note size must be set to size of 1x image*/
        background-size: 1558px 29px;
    }
}
.format-sprite.sprite-format_3d {
  background-position: -5px -5px;
  width: 31px;
  height: 19px;
}
.format-sprite.sprite-format_atmos {
  background-position: -46px -5px;
  width: 70px;
  height: 19px;
}
.format-sprite.sprite-format_dbox {
  background-position: -126px -5px;
  width: 95px;
  height: 19px;
}
.format-sprite.sprite-format_dbox_vr {
  background-position: -231px -5px;
  width: 146px;
  height: 19px;
}
.format-sprite.sprite-format_dbox_vr_fr {
  background-position: -231px -5px;
  width: 146px;
  height: 19px;
}
.format-sprite.sprite-format_dolbyatmos {
  background-position: -387px -5px;
  width: 140px;
  height: 19px;
}
.format-sprite.sprite-format_imax2 {
  background-position: -537px -5px;
  width: 64px;
  height: 19px;
}
.format-sprite.sprite-format_imax3d {
  background-position: -611px -5px;
  width: 149px;
  height: 19px;
}
.format-sprite.sprite-format_reald3d {
  background-position: -770px -5px;
  width: 144px;
  height: 19px;
}
.format-sprite.sprite-format_recl {
  background-position: -924px -5px;
  width: 53px;
  height: 19px;
}
.format-sprite.sprite-format_recl_fr {
  background-position: -987px -5px;
  width: 65px;
  height: 19px;
}
.format-sprite.sprite-format_selectseats {
  background-position: -1062px -5px;
  width: 36px;
  height: 19px;
}
.format-sprite.sprite-format_selectseats2 {
  background-position: -1108px -5px;
  width: 45px;
  height: 19px;
}
.format-sprite.sprite-format_ultraAVX {
  background-position: -1163px -5px;
  width: 72px;
  height: 19px;
}
.format-sprite.sprite-format_vip {
  background-position: -1245px -5px;
  width: 44px;
  height: 19px;
}
.format-sprite.sprite-format_imax_vr {
  background-position: -1299px -5px;
  width: 120px;
  height: 19px;
}
.format-sprite.sprite-format_clubhouse {
  background-position: -1433px -5px;
  width: 56px;
  height: 19px;
}
.format-sprite .sprite-format_screenx {
    background-position: -1433px -5px;
    width: 56px;
    height: 19px;
}

.banner-sprite {
  padding: 0 5px;
  display: block;
}
.banner-sprite::before {
  content: "";
  display: table;
}
.banner-sprite::after {
  content: "";
  display: table;
  clear: both;
}
.banner-sprite .banner-image {
  background-image: url(../../img/banner_spritesheet_1x.v4.png);
  background-repeat: no-repeat;
  display: block;
  float: left;
  background-size: 3525px 40px;
  /*Media query to display 2x image*/
  /*--@media(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            background-image: url(../../img/banner_spritesheet_2x.v3.png);--*/
  /*Note size must be set to size of 1x image*/
  /*--background-size: 3421px 40px;
        }--*/
}
.banner-sprite .banner-text {
  font-family: 'FFMarkWebProBold', sans-serif;
  color: #FFF;
  height: 30px;
  font-size: 10px;
  line-height: 20px;
  padding: 6px 3px 4px;
  display: none;
  float: left;
}
.banner-sprite .banner-text.shareBanner {
  font-size: 8px;
  padding: 6px 3px 4px;
}
.banner-sprite .presentation-type {
  font-family: 'FFMarkWebProBold', sans-serif;
  color: #FFF;
  height: 30px;
  font-size: 10px;
  line-height: 20px;
  padding-top: 6px;
  padding-right: 5px;
  padding-bottom: 4px;
  display: block;
  float: right;
}
    .banner-sprite .cc-presentation-type {
        font-family: 'FFMarkWebProBold', sans-serif;
        color: #FFF;
        height: 30px;
        line-height: 20px;
        display: block;
        float: right;
        padding: 5px 0px;
        font-size: .75rem;
    }

    .cc-presentation-type {
        text-align: right;
    }
.banner-sprite .presentation-type.shareBanner {
  font-size: 8px;
  padding: 6px 5px 6px 3px;
}
.sprite-badge_4dx,
.sprite-badge_4dx_fr,
.sprite-badge_4dx_3d,
.sprite-badge_4dx_3d_fr {
  background: #292929;
}
.sprite-badge_4dx .banner-image,
.sprite-badge_4dx_fr .banner-image,
.sprite-badge_4dx_3d .banner-image,
.sprite-badge_4dx_3d_fr .banner-image {
  background-position: -5px -5px;
  width: 62px;
  height: 30px;
}
.sprite-badge_Barco,
.sprite-badge_Barco_fr {
  background: #D1362D;
}
.sprite-badge_Barco .banner-image,
.sprite-badge_Barco_fr .banner-image {
  background-position: -77px -5px;
  width: 177px;
  height: 30px;
}
.sprite-badge_full_recliner_seating,
.sprite-badge_full_recliner_seating_3d {
  background: #025CC0;
}
.sprite-badge_full_recliner_seating .banner-image,
.sprite-badge_full_recliner_seating_3d .banner-image {
  background-position: -264px -5px;
  width: 128px;
  height: 30px;
}
.sprite-badge_full_recliner_seating_fr,
.sprite-badge_full_recliner_seating_3d_fr {
  background: #025CC0;
}
.sprite-badge_full_recliner_seating_fr .banner-image,
.sprite-badge_full_recliner_seating_3d_fr .banner-image {
  background-position: -402px -5px;
  width: 187px;
  height: 30px;
}
.sprite-badge_imax,
.sprite-badge_imax_fr {
  background: #292929;
}
.sprite-badge_imax .banner-image,
.sprite-badge_imax_fr .banner-image {
  background-position: -599px -5px;
  width: 68px;
  height: 30px;
}
.sprite-badge_imax_3d,
.sprite-badge_imax_3d_fr {
  background: #000000;
}
.sprite-badge_imax_3d .banner-image,
.sprite-badge_imax_3d_fr .banner-image {
  background-position: -677px -5px;
  width: 158px;
  height: 30px;
}
.sprite-badge_sensory_friendly {
  background: #025CC0;
}
.sprite-badge_sensory_friendly .banner-image {
  background-position: -845px -5px;
  width: 151px;
  height: 30px;
}
.sprite-badge_sensory_friendly_fr {
  background: #025CC0;
}
.sprite-badge_sensory_friendly_fr .banner-image {
  background-position: -1006px -5px;
  width: 214px;
  height: 30px;
}
.sprite-badge_wg_event_pass,
.sprite-badge_wg_event_pass_fr {
  background: #000000;
}
.sprite-badge_wg_event_pass .banner-image,
.sprite-badge_wg_event_pass_fr .banner-image {
  background-position: -1230px -5px;
  width: 139px;
  height: 30px;
}
.sprite-badge_wg_fan_exp,
.sprite-badge_wg_fan_exp_fr {
  background: #000000;
}
.sprite-badge_wg_fan_exp .banner-image,
.sprite-badge_wg_fan_exp_fr .banner-image {
  background-position: -1379px -5px;
  width: 187px;
  height: 30px;
}
.sprite-dbox,
.sprite-dbox_fr,
.sprite-dbox_3d,
.sprite-dbox_3d_fr,
.sprite-ultraavx_dbox,
.sprite-ultraavx_dbox_fr,
.sprite-ultraavx_atmos_dbox,
.sprite-ultraavx_atmos_dbox_fr,
.sprite-ultraavx_3d_dbox,
.sprite-ultraavx_3d_dbox_fr,
.sprite-ultraavx_3d_atmos_dbox,
.sprite-ultraavx_3d_atmos_dbox_fr,
.sprite-dbox_recl_3d,
.sprite-dbox_recl_3d_fr,
.sprite-dbox_ultraavx_recl_3d_atmos,
.sprite-dbox_ultraavx_recl_3d_atmos_fr,
.sprite-dbox_ultraavx_recl_3d,
.sprite-dbox_ultraavx_recl_3d_fr,
.sprite-dbox_ultraavx_recl_atmos,
.sprite-dbox_ultraavx_recl_atmos_fr,
.sprite-dbox_ultraavx_recl,
.sprite-dbox_ultraavx_recl_fr,
.sprite-dbox_recl,
.sprite-dbox_recl_fr {
  background: #292929;
}
.sprite-dbox .banner-image,
.sprite-dbox_fr .banner-image,
.sprite-dbox_3d .banner-image,
.sprite-dbox_3d_fr .banner-image,
.sprite-ultraavx_dbox .banner-image,
.sprite-ultraavx_dbox_fr .banner-image,
.sprite-ultraavx_atmos_dbox .banner-image,
.sprite-ultraavx_atmos_dbox_fr .banner-image,
.sprite-ultraavx_3d_dbox .banner-image,
.sprite-ultraavx_3d_dbox_fr .banner-image,
.sprite-ultraavx_3d_atmos_dbox .banner-image,
.sprite-ultraavx_3d_atmos_dbox_fr .banner-image,
.sprite-dbox_recl_3d .banner-image,
.sprite-dbox_recl_3d_fr .banner-image,
.sprite-dbox_ultraavx_recl_3d_atmos .banner-image,
.sprite-dbox_ultraavx_recl_3d_atmos_fr .banner-image,
.sprite-dbox_ultraavx_recl_3d .banner-image,
.sprite-dbox_ultraavx_recl_3d_fr .banner-image,
.sprite-dbox_ultraavx_recl_atmos .banner-image,
.sprite-dbox_ultraavx_recl_atmos_fr .banner-image,
.sprite-dbox_ultraavx_recl .banner-image,
.sprite-dbox_ultraavx_recl_fr .banner-image,
.sprite-dbox_recl .banner-image,
.sprite-dbox_recl_fr .banner-image {
  background-position: -1576px -5px;
  width: 79px;
  height: 30px;
}
.sprite-regular,
.sprite-regular_3d {
  background: #025CC0;
}
.sprite-regular .banner-image,
.sprite-regular_3d .banner-image {
  background-position: -1665px -5px;
  width: 77px;
  height: 30px;
}
.sprite-regular_fr,
.sprite-regular_3d_fr {
  background: #025CC0;
}
.sprite-regular_fr .banner-image,
.sprite-regular_3d_fr .banner-image {
  background-position: -1752px -5px;
  width: 79px;
  height: 30px;
}
.sprite-selectseats,
.sprite-selectseats_3d {
  background: #292929;
}
.sprite-selectseats .banner-image,
.sprite-selectseats_3d .banner-image {
  background-position: -1841px -5px;
  width: 123px;
  height: 30px;
}
.sprite-selectseats_fr,
.sprite-selectseats_3d_fr {
  background: #292929;
}
.sprite-selectseats_fr .banner-image,
.sprite-selectseats_3d_fr .banner-image {
  background-position: -1974px -5px;
  width: 143px;
  height: 30px;
}
.sprite-starsandstrollers {
  background: #025CC0;
}
.sprite-starsandstrollers .banner-image {
  background-position: -2127px -5px;
  width: 156px;
  height: 30px;
}
.sprite-starsandstrollers_fr {
  background: #025CC0;
}
.sprite-starsandstrollers_fr .banner-image {
  background-position: -2293px -5px;
  width: 201px;
  height: 30px;
}
.sprite-traditional,
.sprite-traditional_3d {
  background: #025CC0;
}
.sprite-traditional .banner-image,
.sprite-traditional_3d .banner-image {
  background-position: -2504px -5px;
  width: 106px;
  height: 30px;
}
.sprite-traditional_fr,
.sprite-traditional_3d_fr {
  background: #025CC0;
}
.sprite-traditional_fr .banner-image,
.sprite-traditional_3d_fr .banner-image {
  background-position: -2620px -5px;
  width: 115px;
  height: 30px;
}
.sprite-clubhouse {
  background: #004fa8;
}
.sprite-clubhouse .banner-image {
  background-position: -3322px -5px;
  width: 94px;
  height: 30px;
}
.sprite-screenx {
    background: #292929;
}

.sprite-screenx .banner-image {
    background-position: -3426px -5px;
    width: 92px;
    height: 30px;
}

.sprite-ultraavx,
.sprite-ultraavx_fr,
.sprite-ultraavx_atmos,
.sprite-ultraavx_atmos_fr,
.sprite-ultraavx_3d,
.sprite-ultraavx_3d_fr,
.sprite-ultraavx_3d_atmos,
.sprite-ultraavx_3d_atmos_fr,
.sprite-ultraavx_recl_3d_atmos,
.sprite-ultraavx_recl_3d_atmos_fr,
.sprite-ultraavx_recl_atmos,
.sprite-ultraavx_recl_atmos_fr,
.sprite-ultraavx_recl,
.sprite-ultraavx_recl_fr,
.sprite-ultraavx_recl_3d,
.sprite-ultraavx_recl_3d_fr {
  background: #292929;
}
.sprite-ultraavx .banner-image,
.sprite-ultraavx_fr .banner-image,
.sprite-ultraavx_atmos .banner-image,
.sprite-ultraavx_atmos_fr .banner-image,
.sprite-ultraavx_3d .banner-image,
.sprite-ultraavx_3d_fr .banner-image,
.sprite-ultraavx_3d_atmos .banner-image,
.sprite-ultraavx_3d_atmos_fr .banner-image,
.sprite-ultraavx_recl_3d_atmos .banner-image,
.sprite-ultraavx_recl_3d_atmos_fr .banner-image,
.sprite-ultraavx_recl_atmos .banner-image,
.sprite-ultraavx_recl_atmos_fr .banner-image,
.sprite-ultraavx_recl .banner-image,
.sprite-ultraavx_recl_fr .banner-image,
.sprite-ultraavx_recl_3d .banner-image,
.sprite-ultraavx_recl_3d_fr .banner-image {
  background-position: -2745px -5px;
  width: 77px;
  height: 30px;
}
.sprite-vip18plus,
.sprite-vip18plus_3d,
.sprite-vip18plus_3d_fr,
.sprite-vip18plus_fr {
  background: #292929;
}
.sprite-vip18plus .banner-image,
.sprite-vip18plus_3d .banner-image,
.sprite-vip18plus_3d_fr .banner-image,
.sprite-vip18plus_fr .banner-image {
  background-position: -2832px -5px;
  width: 62px;
  height: 30px;
}
.sprite-vip19plus,
.sprite-vip19plus_fr,
.sprite-vip19plus_3d,
.sprite-vip19plus_3d_fr {
  background: #292929;
}
.sprite-vip19plus .banner-image,
.sprite-vip19plus_fr .banner-image,
.sprite-vip19plus_3d .banner-image,
.sprite-vip19plus_3d_fr .banner-image {
  background-position: -2904px -5px;
  width: 62px;
  height: 30px;
}
.sprite-vip19plus_frs,
.sprite-vip19plus_3d_frs {
  background: #292929;
}
.sprite-vip19plus_frs .banner-image,
.sprite-vip19plus_3d_frs .banner-image {
  background-position: -2976px -5px;
  width: 163px;
  height: 30px;
}
.sprite-vip19plus_frs_fr,
.sprite-vip19plus_3d_frs_fr {
  background: #292929;
}
.sprite-vip19plus_frs_fr .banner-image,
.sprite-vip19plus_3d_frs_fr .banner-image {
  background-position: -3149px -5px;
  width: 163px;
  height: 30px;
}
.sprite-badge_4dx_3d .sprite-text-3d,
.sprite-badge_4dx_3d_fr .sprite-text-3d,
.sprite-badge_full_recliner_seating_3d .sprite-text-3d,
.sprite-badge_full_recliner_seating_3d_fr .sprite-text-3d,
.sprite-dbox_3d .sprite-text-3d,
.sprite-dbox_3d_fr .sprite-text-3d,
.sprite-ultraavx_3d_dbox .sprite-text-3d,
.sprite-ultraavx_3d_dbox_fr .sprite-text-3d,
.sprite-ultraavx_3d_atmos_dbox .sprite-text-3d,
.sprite-ultraavx_3d_atmos_dbox_fr .sprite-text-3d,
.sprite-regular_3d .sprite-text-3d,
.sprite-regular_3d_fr .sprite-text-3d,
.sprite-selectseats_3d .sprite-text-3d,
.sprite-selectseats_3d_fr .sprite-text-3d,
.sprite-traditional_3d .sprite-text-3d,
.sprite-traditional_3d_fr .sprite-text-3d,
.sprite-ultraavx_3d .sprite-text-3d,
.sprite-ultraavx_3d_fr .sprite-text-3d,
.sprite-ultraavx_3d_atmos .sprite-text-3d,
.sprite-ultraavx_3d_atmos_fr .sprite-text-3d,
.sprite-vip18plus_3d .sprite-text-3d,
.sprite-vip18plus_3d_fr .sprite-text-3d,
.sprite-vip19plus_3d .sprite-text-3d,
.sprite-vip19plus_3d_fr .sprite-text-3d,
.sprite-vip19plus_3d_frs .sprite-text-3d,
.sprite-vip19plus_3d_frs_fr .sprite-text-3d,
.sprite-ultraavx_recl_3d .sprite-text-3d,
.sprite-ultraavx_recl_3d_fr .sprite-text-3d,
.sprite-dbox_recl_3d .sprite-text-3d,
.sprite-dbox_recl_3d_fr .sprite-text-3d,
.sprite-dbox_ultraavx_recl_3d_atmos .sprite-text-3d,
.sprite-dbox_ultraavx_recl_3d_atmos_fr .sprite-text-3d,
.sprite-dbox_ultraavx_recl_3d .sprite-text-3d,
.sprite-dbox_ultraavx_recl_3d_fr .sprite-text-3d,
.sprite-ultraavx_recl_3d_atmos .sprite-text-3d,
.sprite-ultraavx_recl_3d_atmos_fr .sprite-text-3d {
  display: block;
}
.sprite-ultraavx_dbox .sprite-text-ultraavx,
.sprite-ultraavx_dbox_fr .sprite-text-ultraavx,
.sprite-ultraavx_atmos_dbox .sprite-text-ultraavx,
.sprite-ultraavx_atmos_dbox_fr .sprite-text-ultraavx,
.sprite-ultraavx_3d_dbox .sprite-text-ultraavx,
.sprite-ultraavx_3d_dbox_fr .sprite-text-ultraavx,
.sprite-ultraavx_3d_atmos_dbox .sprite-text-ultraavx,
.sprite-ultraavx_3d_atmos_dbox_fr .sprite-text-ultraavx,
.sprite-dbox_ultraavx_recl_3d_atmos .sprite-text-ultraavx,
.sprite-dbox_ultraavx_recl_3d_atmos_fr .sprite-text-ultraavx,
.sprite-dbox_ultraavx_recl_3d .sprite-text-ultraavx,
.sprite-dbox_ultraavx_recl_3d_fr .sprite-text-ultraavx,
.sprite-dbox_ultraavx_recl_atmos .sprite-text-ultraavx,
.sprite-dbox_ultraavx_recl_atmos_fr .sprite-text-ultraavx,
.sprite-dbox_ultraavx_recl .sprite-text-ultraavx,
.sprite-dbox_ultraavx_recl_fr .sprite-text-ultraavx {
  display: block;
}
.sprite-ultraavx_atmos_dbox .sprite-text-atmos,
.sprite-ultraavx_atmos_dbox_fr .sprite-text-atmos,
.sprite-ultraavx_3d_atmos_dbox .sprite-text-atmos,
.sprite-ultraavx_3d_atmos_dbox_fr .sprite-text-atmos,
.sprite-ultraavx_atmos .sprite-text-atmos,
.sprite-ultraavx_atmos_fr .sprite-text-atmos,
.sprite-ultraavx_3d_atmos .sprite-text-atmos,
.sprite-ultraavx_3d_atmos_fr .sprite-text-atmos,
.sprite-ultraavx_recl_atmos .sprite-text-atmos,
.sprite-ultraavx_recl_atmos_fr .sprite-text-atmos,
.sprite-ultraavx_recl_3d_atmos .sprite-text-atmos,
.sprite-ultraavx_recl_3d_atmos_fr .sprite-text-atmos {
  display: block;
}
.sprite-dbox_recl .sprite-text-recl,
.sprite-ultraavx_recl .sprite-text-recl,
.sprite-ultraavx_recl_3d .sprite-text-recl,
.sprite-dbox_recl_3d .sprite-text-recl,
.sprite-ultraavx_recl_3d_atmos .sprite-text-recl,
.sprite-ultraavx_recl_3d_atmos_fr .sprite-text-recl,
.sprite-ultraavx_recl_atmos .sprite-text-recl,
.sprite-ultraavx_recl_atmos_fr .sprite-text-recl,
.sprite-dbox_ultraavx_recl_3d_atmos .sprite-text-recl,
.sprite-dbox_ultraavx_recl_3d_atmos_fr .sprite-text-recl,
.sprite-dbox_ultraavx_recl_3d .sprite-text-recl,
.sprite-dbox_ultraavx_recl_3d_fr .sprite-text-recl,
.sprite-dbox_ultraavx_recl_atmos .sprite-text-recl,
.sprite-dbox_ultraavx_recl_atmos_fr .sprite-text-recl,
.sprite-dbox_ultraavx_recl .sprite-text-recl,
.sprite-dbox_ultraavx_recl_fr .sprite-text-recl {
  display: block;
}
.sprite-ultraavx_recl_fr .sprite-text-recl-long_fr,
.sprite-ultraavx_recl_3d_fr .sprite-text-recl-long_fr,
.sprite-dbox_recl_3d_fr .sprite-text-recl-long_fr,
.sprite-dbox_recl_fr .sprite-text-recl-long_fr {
  display: block;
}
@keyframes spinnerOne {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-wrap {
  position: relative;
  height: 100px;
}
.loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: perspective(1px) translate(-50%, -50%);
  -o-transform: perspective(1px) translate(-50%, -50%);
  -webkit-transform: perspective(1px) translate(-50%, -50%);
  transform: perspective(1px) translate(-50%, -50%);
  margin: 0;
}
.loader-container .loader-spinner {
  border: 4px solid transparent;
  border-top: 4px solid #FFF;
  border-right: 4px solid #FFF;
  border-radius: 100%;
  display: inline-block;
}
.loader-container .spinner-max {
  animation: spinnerOne 3s linear infinite;
  height: 48px;
  width: 48px;
  border-top: rgba(0, 0, 0, 0);
}
.loader-container .spinner-mid {
  animation: spinnerOne 5s linear infinite;
  height: 38px;
  width: 38px;
  border-top: rgba(0, 0, 0, 0);
}
.loader-container .spinner-min {
  animation: spinnerOne 5s linear infinite;
  height: 28px;
  width: 28px;
  border-top: rgba(0, 0, 0, 0);
}
.loader-container.loader-dark .loader-spinner {
  border-top-color: #004ea8;
  border-right-color: #004ea8;
}
.loader-container.tiny-loader .loader-spinner {
  border: 2px solid transparent;
  border-top: 2px solid #FFF;
  border-right: 2px solid #FFF;
  border-radius: 100%;
  display: inline-block;
}
.loader-container.tiny-loader .spinner-max {
  animation: spinnerOne 3s linear infinite;
  height: 24px;
  width: 24px;
  border-top: rgba(0, 0, 0, 0);
}
.loader-container.tiny-loader .spinner-mid {
  animation: spinnerOne 5s linear infinite;
  height: 19px;
  width: 19px;
  border-top: rgba(0, 0, 0, 0);
}
.loader-container.tiny-loader .spinner-min {
  animation: spinnerOne 5s linear infinite;
  height: 14px;
  width: 14px;
  border-top: rgba(0, 0, 0, 0);
}
.remodal[data-remodal-id=seatmap] .loader-container {
    z-index: -1;
}

.remodal[data-remodal-id=seatmap] .loader-container .loader-spinner {
    border-top-color: #004ea8;
    border-right-color: #004ea8;
}
.ie-loader-img {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 40px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #FFF;
  z-index: 100;
  max-width: 100%;
  max-height: 100%;
}
.modal-content .modal-close {
  display: block;
  float: right;
  padding: 10px;
  color: #FFF;
  text-decoration: none;
}
.trailer-modal .modal-close {
  font-size: 2em;
}
@media (min-width: 480px) {
  .trailer-modal .modal-close {
    font-size: 1.2em;
  }
}
.trailer-modal .modal-dialog {
  width: 100%;
  margin: 0;
}
.trailer-modal .modal-dialog .modal-content {
  position: fixed;
  background: #000;
  width: 100%;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  transform: translateY(40%);
}
@media (min-width: 640px) {
    .trailer-modal .modal-dialog .modal-content {
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        -webkit-border-radius: 5px;
        border-radius: 5px;
        height: auto;
        width: auto;
        max-width: 100%;
        left: 50%;
        -moz-transform: translate(-50%, 30%);
        -o-transform: translate(-50%, 30%);
        -webkit-transform: translate(-50%, 30%);
        transform: translate(-50%, 30%);
    }
}
.trailer-modal .modal-dialog .modal-content .brightcove-video {
  height: 0;
  width: 640px;
  max-width: 100%;
  position: relative;
  padding-bottom: 67%;
  background: #000;
}

.trailer-modal .modal-dialog .modal-content .brightcove-video.vjs-fullscreen {
    padding-bottom: 0;
}
.img-modal .modal-close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.5rem;
}
@media (min-width: 992px) {
  .img-modal .modal-close {
    font-size: 0.875rem;
  }
}
.img-modal .modal-dialog {
  width: auto;
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
}
@media (min-width: 992px) {
  .img-modal .modal-dialog {
    margin-top: 30px;
    max-width: 80vw;
  }
}
.img-modal .modal-content {
  background: rgba(0, 0, 0, 0);
  box-shadow: none;
  border: 0px solid #000;
}
.img-modal .modal-img-wrap {
  position: relative;
  padding: 2.5rem;
}
@media (min-width: 992px) {
  .img-modal .modal-img-wrap {
    padding: 5vh 1.75rem;
  }
}
.img-modal img {
  max-height: 75vh;
  max-width: 100%;
}
.slider-modal {
  background: rgba(0, 0, 0, 0.9);
}
.slider-modal .modal-close {
  font-size: 1.5rem;
}
@media (min-width: 992px) {
  .slider-modal .modal-close {
    font-size: 0.875rem;
  }
}
.slider-modal .modal-dialog {
  height: 90vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
  margin: 0;
  max-height: 100%;
}
@media (min-width: 768px) {
  .slider-modal .modal-dialog {
    width: 90vw;
    margin: 0 5vw;
  }
}
.slider-modal .modal-dialog .modal-content {
  display: block;
  margin: 0 auto;
  width: 100%;
  background: rgba(0, 0, 0, 0);
  box-shadow: none;
  border: 0px solid #000;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.slider-modal .modal-dialog .modal-content #royal-slider-modal-container img {
  max-height: 75vh;
  max-width: 80vw;
}
.slider-modal .modal-dialog .modal-content #royal-slider-modal-container .royalSlider {
  clear: both;
  padding: 0 10vw;
}
@media (min-width: 768px) {
  .slider-modal .modal-dialog .modal-content #royal-slider-modal-container .royalSlider {
    padding: 0 5vw;
  }
}
.slider-modal .modal-dialog .modal-content #royal-slider-modal-container .royalSlider .rsOverflow {
  position: static;
}
.slider-modal .modal-dialog .modal-content #royal-slider-modal-container .royalSlider .rsOverflow .rsArrow {
  display: block !important;
  background: rgba(0, 0, 0, 0);
  font-size: 1.25em;
}
@media (min-width: 480px) {
  .slider-modal .modal-dialog .modal-content #royal-slider-modal-container .royalSlider .rsOverflow .rsArrow {
    font-size: 2em;
  }
}
.slider-modal .modal-dialog .modal-content #royal-slider-modal-container .royalSlider .rsOverflow .rsArrow.rsArrowDisabled {
  display: none !important;
}
.slider-modal .modal-dialog .modal-content #royal-slider-modal-container .royalSlider .rsNav .rsNavItem.rsBullet span {
  border: 1px solid #FFF;
  background: rgba(0, 0, 0, 0);
}
.slider-modal .modal-dialog .modal-content #royal-slider-modal-container .royalSlider .rsNav .rsNavItem.rsBullet.rsNavSelected span {
  background: #FFF;
}
.pop-over-error {
  position: absolute;
  top: 100%;
  left: 0px;
  right: 0px;
  background: #d0021b;
  border: #d0021b;
  overflow: visible;
  z-index: 2;
  padding: 10px;
  margin-top: 9px;
  color: #FFF;
  font-weight: 700;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  text-align: left;
  line-height: initial;
}
.pop-over-error .error-top-arrow {
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -10px;
  border: 10px solid transparent;
  border-bottom: 10px solid #d0021b;
}
.pop-over-error .error-top-arrow:before {
  content: "";
  position: absolute;
  top: -9px;
  left: -10px;
  border: 10px solid transparent;
  border-bottom: 10px solid #d0021b;
}
#connectParentIframe {
  margin-top: -20px;
}
#connect-loader {
  height: 200px;
}
.btn-showtime {
  width: auto;
}
.showtime-grid ul.showtime--list {
  margin: 0;
  text-align: left;
  font-weight: 600;
}
.showtime-grid ul.showtime--list > li {
  display: block;
  float: left;
  text-align: center;
  width: auto;
  margin-bottom: 10px;
}
.showtime-grid ul.showtime--list > li .showtime-wrap {
  display: inline-block;
  white-space: nowrap;
  font-size: 0.75rem;
  height: 2.5em;
  text-transform: uppercase;
}
.showtime-grid ul.showtime--list > li .showtime-wrap a {
  font-family: "FFMarkWebPro", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  float: left;
  display: block;
  cursor: pointer;
  text-decoration: none;
  width: 4rem;
  color:  #eeeeee;
}
.showtime-grid ul.showtime--list > li .showtime-wrap a.showtime {
  padding: 6px 0;
  height: 2.5em;
}
.showtime-grid ul.showtime--list > li .showtime-wrap a.seatMapLink.seatMapDisabled:hover,
.showtime-grid ul.showtime--list > li .showtime-wrap a.seatMapLink.seatMapDisabled:focus {
  background: #FFF;
  color: #999999;
  border: 1px solid #777 !important;
}
.showtime-grid ul.showtime--list > li .showtime-wrap a.after-midnight {
  padding: 2px 0;
  position: relative;
}
.showtime-grid ul.showtime--list > li .showtime-wrap a .qt-showtime-day {
  position: absolute;
  bottom: 1px;
  left: 0;
  width: 100%;
  font-size: .8em;
  line-height: 1em;
  display: block;
  text-align: center;
}
.showtime-grid ul.showtime--list > li .showtime-wrap .seatMapLink {
  font-size: 0.75rem;
  height: 2.5em;
  width: 2rem;
  padding: 4px 5px;
}
.showtime-grid ul.showtime--list > li .showtime-wrap .seatMapLink.seatMapDisabled {
  margin-left: -1px;
  width: 2rem;
  padding: 4px 2px;
}
.showtime-grid ul.showtime--list > li .showtime-wrap .seatMapLink.seatMapDisabled:hover,
.showtime-grid ul.showtime--list > li .showtime-wrap .seatMapLink.seatMapDisabled:focus {
  background: #FFF;
  /*color: #999999;*/
}
.showtime-grid ul.showtime--list > li .showtime-wrap .seatMapDisabled {
  font-family: "FFMarkWebPro", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  height: 2.5em;
  cursor: default;
  margin-left: 0;
  border: 1px solid #A7A8AA;
  width: 4rem;
  padding: 6px 0 0;
}
.showtime-grid ul.showtime--list > li .showtime-wrap .seatMapDisabled.after-midnight {
  padding: 2px 0;
  position: relative;
}
.showtime-grid ul.showtime--list > li .showtime-wrap .seatMapDisabled.after-midnight .qt-showtime-day {
  position: absolute;
  bottom: 1px;
  left: 0;
  width: 100%;
  font-size: .8em;
  line-height: 1em;
  display: block;
  text-align: center;
}
.showtime-grid ul.showtime--list > li .showtime-wrap .qt-sold-out {
  font-family: "FFMarkWebPro", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  background: #FFF;
  border: solid 1px #A7A8AA;
  width: 4rem;
  height: 2.5em;
  position: relative;
  display: block;
  float: left;
  cursor: default;
  padding: 2px 0;
}
.showtime-grid ul.showtime--list > li .showtime-wrap .qt-sold-out .qt-showtime {
  color: #A7A8AA;
  font-size: 0.75rem;
  display: block;
  text-align: center;
}
.showtime-grid ul.showtime--list > li .showtime-wrap .qt-sold-out .qt-sold-out-text {
  position: absolute;
  bottom: 2px;
  left: 0;
  width: 100%;
  color: #d0021b;
  font-size: .6em;
  line-height: 1em;
  display: block;
  text-align: center;
  font-weight: bold;
}
.showtime-grid ul.showtime--list > li .showtime-wrap .qt-sold-out .qt-showtime-day {
  display: none;
}
.seatMapDisabled {
    border: 1px solid #777 !important;
}
.seatMapLink,
.seatMapDisabled {
  float: left;
  border: 1px solid #1c4fa2;
  background-color: #FFF;
  padding: 3px;
  margin: 0px 0px 0px -1px;
  height: 2.5em;
  width:2rem;
  padding: 4px 5px !important;
}
.seatMapDisabledHeight {
  height: 100%;
}
.seatMapLink {
  text-decoration: none;
}

.seatMapDisabled
.seatMapDisabled:hover,
.seatMapDisabled :focus {
    color: #777777 !important;
    border: 1px solid #777777 !important;
}

.seatMapLink:hover,
.seatMapLink:focus {
    text-decoration: none;
    background-color: #00358E;
}

.seatMapImage {
    background-image: url(../../img/seatmapicon.svg);
    background-repeat: no-repeat;
    height: 100%;
}

.seatMapLink:hover .seatMapImage,
.seatMapLink:focus .seatMapImage {
    background-image: url(../../img/seatmapicondisabled.svg);
    margin-left: -3px;
    margin-top: -2px;
    height: 2.5em;
    transform: scale(0.85);
}


.seatMapImage:hover {
    background-image: url(../../img/seatmapicondisabled.svg);
    margin-left: -3px;
    margin-top: -2px;
    height: 2.5em;
    transform: scale(0.85);
}


.showtime-trailer-link {
  display: inline;
}
.bottom-btnShowtimes {
  text-align: center;
  margin-left: 5%;
}
.showtime-type {
  vertical-align: middle;
}
.showtime {
  display: block;
  float: left;
  text-align: center;
  padding: 6px 0;
  border: 1px solid #004ea8;
  font-size: 0.75rem;
  white-space: nowrap;
  width: 4rem;
}
.showtime.disabled {
  color: #777777;
  border: solid 1px #777777;
  background-color: #fff;
  cursor: default;
  min-width: 115px;
}
.showtime.disabled-past {
  color: #777777;
  border: solid 1px #777777;
  background-color: #fff;
  cursor: default;
}
a.showtime {
  background-color: #1c4fa2 !important;
  margin: 0;
  font-weight: 700;
  margin-left: 0px;
  height: 2.5em;
  text-decoration: none;
  color: #fff;
  border: 1px solid #1c4fa2;
}
a.showtime:hover,
a.showtime:active,
a.showtime:focus {
  background-color: #00358E !important;
   border: 1px solid #00358E !important;
}
a.showtime.noclick {
  background-color: #777777;
  border-color: #b3b3b3;
  color: #777777;
  cursor: default;
  min-width: 115px;
}
a.showtime i {
  position: relative;
  top: 1px;
}
span.showtime {
  background-color: #abaeb5 !important;
  font-weight: bold;
  color: #eeeeee !important;
  border: 1px solid #abaeb5;
}

.smartbanner.smartbanner-android {
    padding-top: 12px;
}
/*LAYOUT STYLES*/
.interstitial-ad-container {
    position: absolute;
    width: 100%;
    z-index: 1001;
    top: 0;
}

.top-ad-container {
    background: #0A0B0D;
}
.top-ad-container > div:first-child {
    position: static;
    width: 100%;
   }

.top-ad-container > * {
  display: inline-block;
  overflow: hidden;
}
.top-ad-container > * > * {
  display: inline-block;
}
.top-ad-container iframe {
  display: inline-block;
  max-width: 100%;
  margin: 0;
}
#featured-area {
  margin-top: 5px;
  margin-bottom: 30px;
  position: relative;
}

@media (max-width: 625px) {
    #featured-area .featured-three-fifths {
        padding-left: 15px;
        padding-right: 15px;
        height: auto;
        width: auto;
    }
    #quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane .quick-tickets-header h2 {
        margin-top: 16px!important;
    }
}

@media (min-width: 626px) {
    #featured-area .featured-three-fifths {
        width: auto;
        padding-left: 14px;
        padding-right: 15px;
        height: auto;
    }

    .quick-ticket-slider .rs-content a, .quick-ticket-slider .rs-content a img {
        width: 100%;
    }

    #quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane .quick-tickets-header h2 {
        margin-top: 16px !important;
    }
}

@media (min-width: 992px) {
    #featured-area .featured-three-fifths {
        display: block;
        padding-left: 15px;
        padding-right: 15px;
    }
}
#featured-area .featured-two-fifths {
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 992px) {
  #featured-area .featured-two-fifths {
    float: left;
    width: 40%;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  #featured-area .no-left-pad-md {
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .featured-area .left-underlined-area {
    padding-left: 0;
    margin-left: 15px;
    border-bottom: 1px solid #A7A8AA;
    min-height: 300px;
  }
}

.loading-block-slider {
  min-height: 326px;
  width: 100%;
  display: block;
}
/*Quick Ticket Styles*/
#quick-tickets-wrapper {
  z-index: 82;
  overflow: visible;
  font-family: 'FFMarkWebPro', "Helvetica Neue", helvetica, arial, sans-serif;
  font-weight: 400;
  width: 100%;
  min-height: 300px;
}
@media (min-width: 992px) {
  #quick-tickets-wrapper {
    margin-left: 0px;
    margin-top: 0px;
    float: left;
    position: relative;
    height: 23.28571429em;
  }
}
#quick-tickets-wrapper.one-third-right {
  display: block;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}
@media (min-width: 992px) {
  #quick-tickets-wrapper.one-third-right {
    position: absolute;
    width: 33.33333333%;
    left: 66.66666666%;
    margin-left: 0px;
    margin-top: 0px;
    float: left;
    height: 100%;
  }
}
#quick-tickets-wrapper a:hover,
#quick-tickets-wrapper a:focus {
  text-decoration: none;
}
#quick-tickets-wrapper #quick-tickets-search {
  position: relative;
  background-color: #FFF;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: auto;
  -webkit-transition: height 0.75s ease-in-out;
  -moz-transition: height 0.75s ease-in-out;
  transition: height 0.75s ease-in-out;
  z-index: 11;
  overflow: visible;
  /* Styles for Search Pane */
}
@media (min-width: 992px) {
  #quick-tickets-wrapper #quick-tickets-search {
    height: 100%;
    z-index: 82;
  }
}
#quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane {
  background-color: #FFF;
  color: #004ea8;
  display: block;
  left: 0%;
  top: 0%;
  position: relative;
  /*z-index: 82;*/
  -webkit-transition: left 0.5s ease-in-out;
  -moz-transition: left 0.5s ease-in-out;
  transition: left 0.5s ease-in-out;
  width: 100%;
  height: auto;
  float: left;
  overflow: visible;
  z-index: 1;
}
@media (min-width: 992px) {
  #quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane {
    background-color: #FFF;
    height: 100%;
    z-index: 83;
  }
}
#quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane.move {
  left: 0%;
  /*box-sizing: content-box;*/
}
@media (min-width: 992px) {
  #quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane.move {
    left: -100%;
    border-right: 1px solid #A7A8AA;
  }
}
@media (min-width: 992px) {
  #quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane.move .single.single-tabbed.quick-tickets-body {
    border-right: none;
  }
}
#quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane.qt-fixed-options {
  border: 1px solid #A7A8AA;
}
@media (min-width: 992px) {
  #quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane.qt-fixed-options {
    height: 100%;
  }
}
#quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane.qt-fixed-options .quick-tickets-header {
  margin-left: 15px;
  padding-bottom: 30px;
  padding-top: 15px;
}
#quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane.qt-fixed-options .quick-tickets-content-wrap {
  position: static;
  left: 0px;
  bottom: unset;
  width: 100%;
}
#quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane.qt-fixed-options .single.single-tabbed.quick-tickets-body {
  border: none;
  padding-bottom: 30px;
}
@media (min-width: 992px) {
  #quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane.qt-fixed-options .single.single-tabbed.quick-tickets-body {
    padding-bottom: 0;
  }
}
#quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane.qt-fixed-options .single.single-tabbed.quick-tickets-body .quick-tickets-selection-area {
  margin-bottom: 30px;
}
@media (max-width: 991px) {
  #quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane.qt-fixed-options .single.single-tabbed.quick-tickets-body .quick-tickets-selection-area {
    float: none;
    margin-left: 15px;
    margin-right: 15px;
    width: auto;
  }
}
#quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane.qt-fixed-options .single.single-tabbed.quick-tickets-body .quick-tickets-selection-area:last-child {
  margin-bottom: 10px;
}
#quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane.error-border {
  border-color: #d0021b;
}
#quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane .quick-tickets-header {
  padding: 0 0 10px 0;
  margin-left: 8.333%;
  position: relative;
  /*left: 8.333%;*/
}
@media (min-width: 992px) {
  #quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane .quick-tickets-header {
    padding-top: 15px;
  }
}
#quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane .quick-tickets-header h2 {
  color: #004ea8;
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 400;
}
@media (min-width: 992px) {
  #quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane .quick-tickets-header h2 {
    font-size: 1.5em;
  }
}
@media (min-width: 960px) {
  #quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane .quick-tickets-header h2 {
    font-size: 1.71429em;
    padding-right: 2em;
  }
}
#quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane .quick-tickets-header h2 .quick-ticket-tooltip {
  position: relative;
  top: 3px;
  font-size: 1.125em;
  color: #004ea8;
  display: inline-block;
  cursor: pointer;
  font-weight: 400 !important;
  /*@media(min-width:@quick-tickets-collapse-width) {
                            top: 2px;
                        }*/
  /*.info-icon {
                            position: relative;
                            font-size: 0.95em;
                            left: 4px;
                            top: -5px;

                            @media(min-width: @quick-tickets-collapse-width) {
                                left: 4px;
                                top: -4px;
                            }
                        }*/
}
#quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane .quick-tickets-header h2 .quick-ticket-tooltip:focus,
#quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane .quick-tickets-header h2 .quick-ticket-tooltip:hover {
  color: #004ea8;
}
#quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane .quick-tickets-header h2 .quick-ticket-tooltip-content {
  font-family: 'FFMarkWebProBold', "Helvetica Neue", helvetica, arial, sans-serif;
  font-weight: 400;
  font-size: 0.5833333333333333em;
  display: block;
  padding: 1em 3em 1em 1em;
}
#quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane .quick-tickets-header h2 .quick-ticket-tooltip-content .fa.fa-times-circle-o {
  font-size: 2em;
  position: absolute;
  top: 5px;
  right: 5px;
  color: #004ea8;
}
#quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane .quick-tickets-header h2 .quick-ticket-tooltip-content .fa.fa-times-circle-o:focus,
#quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane .quick-tickets-header h2 .quick-ticket-tooltip-content .fa.fa-times-circle-o:hover {
  color: #003c71;
}
#quick-tickets-wrapper #quick-tickets-search .quick-tickets-content-wrap {
  display: block;
  float: left;
  position: relative;
  width: 100%;
  right: 0;
  left: 0;
  padding-bottom: 0;
  z-index: 0;
}
@media (min-width: 992px) {
  #quick-tickets-wrapper #quick-tickets-search .quick-tickets-content-wrap {
    bottom: 0;
    left: 15px;
    z-index: 2;
    top: unset;
    box-shadow: none;
    position: absolute;
    width: auto;
  }
}
#quick-tickets-wrapper #quick-tickets-search .quick-tickets-content-wrap .quick-tickets-nav {
  white-space: normal;
  padding-left: 0px;
  margin-left: 0px;
  float: left;
  overflow: visible;
  position: relative;
  z-index: 3;
  top: 1px;
  width: 90%;
  left: 5%;
}
#quick-tickets-wrapper #quick-tickets-search .quick-tickets-content-wrap .quick-tickets-nav ul {
  /*height: auto;*/
  width: 100%;
  position: relative;
  float: left;
  overflow: hidden;
  z-index: 3;
}
#quick-tickets-wrapper #quick-tickets-search .quick-tickets-content-wrap .quick-tickets-nav ul li {
  /*height: auto;*/
  display: block;
  float: left;
  background-color: rgba(0, 0, 0, 0);
  margin-left: 0px;
  color: #004ea8;
  width: 50%;
  overflow: visible;
}
#quick-tickets-wrapper #quick-tickets-search .quick-tickets-content-wrap .quick-tickets-nav ul li a {
  background: #004ea8;
  /*border: @quick-ticket-inactive-border;*/
  color: #FFF;
  display: inline-block;
  font-weight: 600;
  font-size: 1em;
  /*height: 100%;*/
  padding: 10px 5px 30px;
  position: relative;
  text-align: center;
  text-transform: none;
  width: 100%;
  word-wrap: normal;
  margin-top: 5px;
  outline: none;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.9);
  margin-bottom: -20px;
}
@media (min-width: 992px) {
  #quick-tickets-wrapper #quick-tickets-search .quick-tickets-content-wrap .quick-tickets-nav ul li a {
    font-size: 0.9em;
  }
}
@media (min-width: 875px) {
  #quick-tickets-wrapper #quick-tickets-search .quick-tickets-content-wrap .quick-tickets-nav ul li a {
    font-size: 1.071428571428571em;
  }
}
@media (min-width: 992px) {
  #quick-tickets-wrapper #quick-tickets-search .quick-tickets-content-wrap .quick-tickets-nav ul li a:hover {
    background: #003c71;
  }
}
#quick-tickets-wrapper #quick-tickets-search .quick-tickets-content-wrap .quick-tickets-nav ul li a:focus {
  background: #003c71;
}
#quick-tickets-wrapper #quick-tickets-search .quick-tickets-content-wrap .quick-tickets-nav ul li a.active {
  background: #FFF;
  border-top: 1px solid #A7A8AA;
  border-left: 1px solid #A7A8AA;
  border-right: 1px solid #A7A8AA;
  color: #004ea8;
  cursor: default;
  text-transform: none;
  padding-top: 15px;
  margin-top: 0px;
  text-shadow: none;
}
#quick-tickets-wrapper #quick-tickets-search .quick-tickets-content-wrap .qt-fixed-heading {
  font-family: 'FFMarkWebProBold', "Helvetica Neue", helvetica, arial, sans-serif;
  padding: 0 15px;
  font-size: 1.2em;
  font-weight: 500;
  color: #000;
}
#quick-tickets-wrapper #quick-tickets-search .quick-tickets-content-wrap .quick-tickets-dropdown-wrap {
  padding: 20px 0px 10px;
  overflow: visible;
}
@media (min-width: 992px) {
  #quick-tickets-wrapper #quick-tickets-search .quick-tickets-content-wrap .quick-tickets-dropdown-wrap {
    padding: 20px 15px;
  }
}
#quick-tickets-wrapper #quicktickets-fallback-error {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #d0021b;
  height: 100%;
  width: 100%;
  z-index: 100;
  display: none;
  background: #FFF;
}
#quick-tickets-wrapper #quicktickets-fallback-error.qt-show {
  display: block;
}
#quick-tickets-wrapper #quicktickets-fallback-error.qt-show .qt-error-content {
  display: block;
}
#quick-tickets-wrapper #quicktickets-fallback-error .qt-error-content {
  display: none;
  color: #d0021b;
  font-size: 1.142857142857143em;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  -moz-tranform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#quick-tickets-wrapper #quicktickets-fallback-error .qt-error-content a {
  text-decoration: underline;
}
#quick-tickets-wrapper .error-border {
  border-color: #d0021b;
}
#quick-tickets-wrapper .error-border .single.single-tabbed.quick-tickets-body {
  border-bottom: 1px solid #d0021b;
}
@media (min-width: 992px) {
  #quick-tickets-wrapper .error-border .single.single-tabbed.quick-tickets-body {
    border-bottom: 1px solid #A7A8AA;
  }
}
#quick-tickets-wrapper .quick-tickets-loading-animation {
  background-color: #444;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin-top: 0;
  z-index: 100;
}
#quick-tickets-wrapper .quick-tickets-loading-animation.loader {
  display: block;
  z-index: 83;
}
@media (min-width: 992px) {
  #quick-tickets-wrapper .quick-tickets-loading-animation.loader {
    z-index: 1;
  }
}
#quick-tickets-wrapper .quick-tickets-loading-animation.initial-loader {
  display: block;
}
.single.single-tabbed.quick-tickets-body {
  background: #FFF;
  border-radius: 0px;
  border: 1px solid #A7A8AA;
  color: #FFF;
  margin-bottom: 0px;
  z-index: 2;
  box-shadow: none;
  position: relative;
  float: left;
  width: 100%;
}
.single.single-tabbed.quick-tickets-body .tabs-container {
  height: 0px;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.5s linear;
  -moz-transition: opacity 0.5s linear;
  transition: opacity 0.5s linear;
}
.single.single-tabbed.quick-tickets-body .tabs-container.visible {
  height: auto;
  overflow: visible;
  visibility: visible;
  opacity: 1;
}
.quick-tickets-selection-area {
  background-color: #FFF;
  color: #004ea8;
  border: 1px solid #004ea8;
  height: auto;
  overflow: visible;
  vertical-align: middle;
  width: 90%;
  margin-left: 5%;
  position: relative;
  float: left;
  text-align: center;
  -webkit-transition: opacity 0.25s linear;
  -moz-transition: opacity 0.25s linear;
  transition: opacity 0.25s linear;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .quick-tickets-selection-area {
    vertical-align: middle;
    width: 100%;
    margin-left: 0%;
  }
  .quick-tickets-selection-area:last-child {
    margin-bottom: 0px;
  }
}
.quick-tickets-selection-area .down-arrow {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -12px;
  border: 12px solid transparent;
  border-top: 8px solid #004ea8;
}
.quick-tickets-selection-area .down-arrow:before {
  content: "";
  position: absolute;
  top: -9px;
  left: -12px;
  border: 12px solid transparent;
  border-top: 8px solid #FFF;
}
.quick-tickets-selection-area .quick-tickets-selection-wrap {
  width: 100%;
}
.quick-tickets-selection-area .quick-tickets-selection-wrap .icon-wrap {
  float: left;
  font-size: 1.6em;
  left: 0;
  top: 0;
  position: absolute;
  width: 2em;
  height: 100%;
  z-index: 0;
}
.quick-tickets-selection-area .quick-tickets-selection-wrap .icon-wrap .fa {
  padding: 5px;
  position: absolute;
  top: 0.15em;
  left: 50%;
  -moz-tranform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.quick-tickets-selection-area .quick-tickets-selection-wrap .right-icon-wrap {
  font-size: 1.2em;
  right: 0;
  top: -1px;
  position: absolute;
  text-align: center;
  vertical-align: middle;
  height: 100%;
  width: 2em;
  z-index: 0;
}
.quick-tickets-selection-area .quick-tickets-selection-wrap .right-icon-wrap i {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-tranform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.quick-tickets-selection-wrap .selectize-control.single .selectize-input:after {
    display: none;
}
.quick-tickets-selection-area.disabled {
  color: #A7A8AA;
  border: 1px solid #A7A8AA;
}
.quick-tickets-selection-area.disabled .down-arrow {
  border-top: 8px solid #A7A8AA;
}
/* Styles for Time Selection Pane */
#quicktickets-showtime-selection-wrapper {
  position: relative;
  height: 0px;
  width: 100%;
  display: block;
  float: left;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  transition: all 0.5s linear;
  -moz-tranform: scaleY(0);
  -webkit-transform: scaleY(0);
  -o-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  -moz-transform-origin: top;
  -webkit-transform-origin: top;
  -o-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  border-top: none;
  border-right: 1px solid #A7A8AA;
  border-bottom: 1px solid #A7A8AA;
  border-left: 1px solid #A7A8AA;
}
@media (min-width: 992px) {
  #quicktickets-showtime-selection-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 1px;
    height: 316px;
    width: auto;
    margin-left: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    -moz-tranform: scaleY(1);
    -webkit-transform: scaleY(1);
    -o-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    border-top: 1px solid #A7A8AA;
    border-right: 1px solid #A7A8AA;
    border-bottom: 1px solid #A7A8AA;
    border-left: none;
    /*padding: 15px;*/
  }
}
#quicktickets-showtime-selection-wrapper .quick-tickets-close-btn {
  color: #004ea8;
  font-size: 1.7857em;
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  text-align: center;
  z-index: 1;
  cursor: pointer;
}
#quicktickets-showtime-selection-wrapper .quick-tickets-close-btn:hover,
#quicktickets-showtime-selection-wrapper .quick-tickets-close-btn:focus {
  color: #003c71;
}
#quicktickets-showtime-selection-wrapper.expand {
  height: auto;
  -moz-tranform: scaleY(1);
  -webkit-transform: scaleY(1);
  -o-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
  /*@media(min-width:@quick-tickets-collapse-width) {
            height: 316px;
        }*/
}
#quicktickets-showtime-selection-wrapper.expand .quick-tickets-btn-wrap {
  padding: 0;
  margin: 0 15px 0;
}
@media (min-width: 992px) {
  #quicktickets-showtime-selection-wrapper.expand .quick-tickets-btn-wrap {
    background: rgba(255, 255, 255, 0);
    padding: 0 15px 10px;
    margin: 0;
  }
}
#quicktickets-showtime-selection-wrapper .btn-series {
  height: auto;
  display: block;
  margin: 0px 15px 5px;
  text-align: center;
  white-space: nowrap;
  text-wrap: none;
}
@media (min-width: 992px) {
  #quicktickets-showtime-selection-wrapper .btn-series {
    margin: 0px 15px 5px;
  }
}
#quicktickets-showtime-selection-wrapper .btn-series .qt-btn-series {
  font-size: 0.75em;
  display: block;
  width: 48%;
  margin: 0px 0px 0px 1%;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  float: right;
}
@media (min-width: 992px) {
  #quicktickets-showtime-selection-wrapper .btn-series .qt-btn-series {
    font-size: 0.68em;
  }
}
@media (min-width: 900px) {
  #quicktickets-showtime-selection-wrapper .btn-series .qt-btn-series {
    font-size: 0.75em;
  }
}
#quicktickets-showtime-selection-wrapper .btn-series .qt-btn-series:first-child {
  margin-left: 0;
  margin-right: 1%;
  float: left;
}
#quicktickets-showtime-selection-wrapper .btn-series .qt-btn-series.btn-red {
  background: #d0021b;
  border: 1px solid #d0021b;
}
#quicktickets-showtime-selection-wrapper .btn-series .qt-btn-series.btn-red:hover,
#quicktickets-showtime-selection-wrapper .btn-series .qt-btn-series.btn-red:focus {
  background: #6b010e;
  border: 1px solid #6b010e;
}
#quicktickets-showtime-selection-wrapper .quick-tickets-mobile-select-prompt {
  font-family: 'FFMarkWebProBold', "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.85714285714285714285714285714286em;
  display: block;
  padding: 1.333em 0;
  margin: 0 3.67em 0 15px;
  background: #FFF;
  font-weight: bold;
  text-align: left;
}
@media (min-width: 992px) {
  #quicktickets-showtime-selection-wrapper .quick-tickets-mobile-select-prompt {
    padding-top: 20px;
  }
}
#quicktickets-showtime-selection-wrapper .movie-cat-wrap {
  margin-bottom: 10px;
}
@media (min-width: 500px) {
  #quicktickets-showtime-selection-wrapper .showtime-grid ul.showtime--list {
    text-align: left;
  }
}
@media (min-width: 992px) {
  #quicktickets-showtime-selection-wrapper .showtime-grid ul.showtime--list {
    text-align: left;
  }
}
@media (min-width: 500px) {
  #quicktickets-showtime-selection-wrapper .showtime-grid ul.showtime--list > li {
    display: inline-block;
    float: none;
  }
}
@media (min-width: 992px) {
  #quicktickets-showtime-selection-wrapper .showtime-grid ul.showtime--list > li {
    display: block;
    float: left;
    width: auto;
    padding: 0;
  }
}
#quicktickets-showtime-selection-wrapper .showtime-grid ul.showtime--list > li .showtime-wrap {
  height: 2.5em !important;
}
#quicktickets-showtime-selection-wrapper .showtime-grid ul.showtime--list > li .showtime-wrap a.showtime {
  height: 2.5em !important;
}
#quicktickets-showtime-selection-wrapper .showtime-grid ul.showtime--list > li .showtime-wrap .seatMapLink {
  height: 2.5em !important;
  /*padding: 5px 3px;*/
}
#quicktickets-showtime-selection-wrapper .showtime-grid ul.showtime--list > li .showtime-wrap .seatMapDisabled {
  height: 2.5em !important;
}
#quicktickets-showtime-selection-wrapper .showtime-grid ul.showtime--list > li .showtime-wrap .qt-sold-out {
  font-weight: bold;
}
#quicktickets-showtime-selection-wrapper .showtime-grid ul.showtime--list > li .showtime-wrap .qt-sold-out .qt-showtime {
  font-size: .85714em;
}
#quicktickets-showtime-selection-wrapper .showtime-grid ul.showtime--list > li .showtime-wrap .qt-sold-out .qt-sold-out-text {
  bottom: 0;
}
#quicktickets-showtime-selection-wrapper .showtime-grid ul.showtime--list > li .showtime-wrap .popover .popover-content {
  max-width: 80px;
  font-size: 0.9em;
  padding: 5px;
  text-align: center;
}
#quicktickets-showtime-selection-wrapper .showtime-grid ul.showtime--list > li .showtime-wrap.online-ticketing .popover .popover-content {
  font-size: 1em;
}
#quicktickets-showtime-selection-wrapper .showtimes-tab-error {
  display: block;
  position: relative;
  height: auto;
  left: 0;
  right: 0;
  float: left;
  padding: 3em 10px;
  margin: 0 20px;
  /*&.shown + .quick-tickets-mobile-close-btn {
            bottom: 20px;
        }*/
}
@media (min-width: 992px) {
  #quicktickets-showtime-selection-wrapper .showtimes-tab-error {
    background: rgba(0, 0, 0, 0);
    display: block;
    width: 100%;
    height: 22.57142858em;
    /*position: absolute;
        top: 10px;
        left: 2.5%;
        right: 2.5%;
        bottom: 0;*/
    margin: 0;
    padding: 20px 10px;
    padding-bottom: 0;
  }
}
#quicktickets-showtime-selection-wrapper .showtimes-tab-error .tab-error-content {
  display: block;
  color: #d0021b;
  font-size: 1.142857142857143em;
  text-align: center;
}
@media (min-width: 992px) {
  #quicktickets-showtime-selection-wrapper .showtimes-tab-error .tab-error-content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    -moz-tranform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
@media (max-width: 991px) {
  #quicktickets-showtime-selection-wrapper .ngsb-wrap {
    height: auto !important;
  }
}
#quicktickets-showtime-selection-wrapper .ngsb-scrollbar {
  width: 6px;
}
@media (max-width: 991px) {
  #quicktickets-showtime-selection-wrapper .ngsb-scrollbar {
    display: none;
  }
}
#quicktickets-showtime-selection-wrapper .ngsb-scrollbar .ngsb-thumb-container .ngsb-thumb-pos .ngsb-thumb {
  background: #d8d8d8;
}
#quicktickets-showtime-selection-wrapper .ngsb-scrollbar .ngsb-thumb-container .ngsb-track {
  border-radius: 0;
  background: #a7a8aa;
}
.quick-tickets-selection-area .quick-tickets-selection-wrap .selectize-open ~ .icon-wrap,
.quick-tickets-selection-area .quick-tickets-selection-wrap .selectize-open ~ .right-icon-wrap {
  z-index: 1;
}

/*.quick-ticket-slider .rsOverflow {
  height: 100% !important;
}

@media screen and (max-width: 420px) {
    .quick-ticket-slider .rsOverflow {
        height: 100% !important;
        width: 100% !important;
    }
}*/

.quick-ticket-slider .rs-content a {
  max-width: 100%;
  width: 100%;
}
.quick-ticket-slider .rs-content a img {
  width: 100%;
}
.loading-block-quick-ticket {
  min-height: 326px;
  width: 100%;
  display: block;
}
.blog-box {
  height: auto;
}
.blog-box .fill-height {
  height: auto;
}
@media (min-width: 768px) {
  .blog-box .fill-height {
    height: 100%;
  }
}
@media (min-width: 768px) {
  .blog-box {
    height: 25em;
  }
}
@media (min-width: 1082px) {
  .blog-box {
    height: 24em;
  }
}
.tall-blog-box {
  height: auto;
}
.tall-blog-box .fill-height {
  height: auto;
}
@media (min-width: 768px) {
  .tall-blog-box .fill-height {
    height: 100%;
  }
}
@media (min-width: 768px) {
  .tall-blog-box {
    height: 27em;
  }
}
@media (min-width: 1082px) {
  .tall-blog-box {
    height: 26em;
  }
}
.theatre-title {
    font-family: 'FFMarkWebProBold', sans-serif;
    margin-bottom: 0px;
}

.theatre-details-page-address {
    font-weight: 600;
    font-size: 0.929em;
}

.theatre-details-page-phone {
    font-weight: 600;
    font-size: 0.929em;
}

.connect-add-to-favs-btn {
    font-size: 1.725em;
    position: absolute;
    outline: none;
    max-width: 100%;
    white-space: normal;
    text-wrap: normal;
    margin-right: 50px;
    top: 30px;
}
    .connect-add-to-favs-btn:focus {
        outline: 5px auto -webkit-focus-ring-color;
    }

    .connect-add-to-favs-btn .tooltip {
        font-size: 0.875em;
    }

    .connect-add-to-favs-btn .fa-star {
        display: none;
    }
    .connect-add-to-favs-btn.favourited .fa-star {
        display: block;
        color: #EDC227;
    }
    .connect-add-to-favs-btn .fa-star-o {
        display: block;
    }
    .connect-add-to-favs-btn.favourited .fa-star-o {
        display: none;
    }

.connect-add-to-favs-btn.favourited .conn-add-fav-text {
  display: none;
}
.connect-add-to-favs-btn.favourited .conn-fav-text {
  display: inline;
}
.connect-add-to-favs-btn .conn-add-fav-text {
  display: inline;
}
.connect-add-to-favs-btn .conn-fav-text {
  display: none;
}
.theatre-admission-pricing-wrap .date-selection-dropdown .dropdown-toggle {
  font-family: 'FFMarkWebProBold', sans-serif;
}
.theatre-admission-pricing-wrap .format-price-table {
  table-layout: fixed;
  border-collapse: separate;
  font-size: 0.85rem;
}
.theatre-admission-pricing-wrap .format-price-table thead {
  font-family: 'FFMarkWebProBold', sans-serif;
  color: #4D4D4D;
}
.theatre-admission-pricing-wrap .format-price-table thead td {
  border-top: none;
  padding-top: 10px;
  padding-bottom: 10px;
  vertical-align: top;
  height: auto;
  background: #FFF;
}
    .theatre-admission-pricing-wrap .format-price-table tbody td {
        font-size: 0.809em;
        border-top: 1px solid #FFF;
        padding: 0 10px;
        background: #F5F5F5;
    }
.theatre-admission-pricing-wrap .format-price-table tbody td.format-cell {
  width: 100px;
  height: 30px;
  padding: 5px;
  background: #292929;
  color: #FFF;
  font-family: 'FFMarkWebProBold', sans-serif;
  font-size: 0.7142857142857143em;
}
@media (min-width: 400px) {
  .theatre-admission-pricing-wrap .format-price-table tbody td.format-cell {
    font-size: 0.875em;
  }
}
.theatre-showtimes-table-wrapper form {
    margin-bottom: 0px;
}
.theatre-showtimes-table-wrapper .movie-cat-wrap {
  margin-bottom: 10px;
}
.theatre-showtimes-table-wrapper .trailer-link .fa {
    font-size: 2.4em;
}
.theatre-showtimes-table-wrapper .trailer-link span {
    float: left;
    clear: none;
    font-size: 11px;
    color: #004ea8;
}
.theatre-showtimes-table-wrapper .movie-showtimes-row .running-time-text {
    font-size: 0.857142857em;
}
.theatre-showtimes-table-wrapper .movie-showtimes-hidden {
  visibility: hidden;
  height: 0px;
}
.movie-cash-wrapper {
  position: relative;
}
.movie-cash-promo-input {
  border: 1px solid #d0d0d0;
  padding: 8px 8px 6px;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 1rem;
  line-height: 1.25rem;
}
.movie-cash-select-field {
  padding: 5px 0;
  position: relative;
}
.movie-cash-select-field .selectize-input {
  padding: 8px 8px 6px;
}
.movie-cash-select-field .selectize-input > input,
.movie-cash-select-field .selectize-input > .item {
  width: 100%;
  margin: 0;
  font-size: 1rem;
  line-height: 1.25rem;
}
.movie-cash-validate-btn {
  width: 100%;
  text-align: center;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 8px 8px 6px;
  position: relative;
  margin-top: 10px;
}
@media (min-width: 768px) {
  .movie-cash-validate-btn {
    margin-top: 0;
  }
}
.movie-cash-showtime-selection-wrapper {
  margin: 20px 0;
  border: 1px solid #000;
}
.movie-cash-showtime-selection-wrapper ul.showtime--list {
  margin: 0;
  text-align: left;
  font-weight: 600;
}
.movie-cash-showtime-selection-wrapper ul.showtime--list > li {
  padding: 8px;
  display: block;
  float: left;
  text-align: center;
  width: auto;
}
.movie-cash-showtime-selection-wrapper ul.showtime--list > li .showtime-wrap {
  display: inline-block;
  white-space: nowrap;
  font-size: 1em;
  height: 2em !important;
  text-transform: uppercase;
}
.movie-cash-showtime-selection-wrapper ul.showtime--list > li .showtime-wrap a {
  font-family: "FFMarkWebPro", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1em;
  float: left;
  display: block;
  cursor: pointer;
  text-decoration: none;
  width: 80px;
}
.movie-cash-showtime-selection-wrapper ul.showtime--list > li .showtime-wrap a.showtime {
  padding: 8px 0;
  height: 2.5em !important;
}
.movie-cash-showtime-selection-wrapper ul.showtime--list > li .showtime-wrap a.seatMapLink.seatMapDisabled:hover,
.movie-cash-showtime-selection-wrapper ul.showtime--list > li .showtime-wrap a.seatMapLink.seatMapDisabled:focus {
  background: #FFF;
  color: #777;
}
.movie-cash-showtime-selection-wrapper ul.showtime--list > li .showtime-wrap a.after-midnight {
  padding: 2px 0;
  position: relative;
}
.movie-cash-showtime-selection-wrapper ul.showtime--list > li .showtime-wrap a .qt-showtime-day {
  position: absolute;
  bottom: 1px;
  left: 0;
  width: 100%;
  font-size: .8em;
  line-height: 1em;
  display: block;
  text-align: center;
}
.movie-cash-showtime-selection-wrapper ul.showtime--list > li .showtime-wrap .seatMapLink {
  font-size: 1em;
  height: 2.5em !important;
  width: 30px;
  padding: 9px 3px;
}
.movie-cash-showtime-selection-wrapper ul.showtime--list > li .showtime-wrap .seatMapLink.seatMapDisabled {
  margin-left: -1px;
  width: 30px;
}
.movie-cash-showtime-selection-wrapper ul.showtime--list > li .showtime-wrap .seatMapLink.seatMapDisabled:hover,
.movie-cash-showtime-selection-wrapper ul.showtime--list > li .showtime-wrap .seatMapLink.seatMapDisabled:focus {
  background: #FFF;
  color: #777;
}
.movie-cash-showtime-selection-wrapper ul.showtime--list > li .showtime-wrap .seatMapDisabled {
  font-family: "FFMarkWebPro", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1em;
  height: 2.5em !important;
  cursor: default;
  margin-left: 0;
  border: 1px solid #A7A8AA;
  width: 80px;
  padding: 8px 0;
}
.movie-cash-showtime-selection-wrapper ul.showtime--list > li .showtime-wrap .seatMapDisabled.after-midnight {
  padding: 2px 0;
  position: relative;
}
.movie-cash-showtime-selection-wrapper ul.showtime--list > li .showtime-wrap .seatMapDisabled.after-midnight .qt-showtime-day {
  position: absolute;
  bottom: 1px;
  left: 0;
  width: 100%;
  font-size: .8em;
  line-height: 1em;
  display: block;
  text-align: center;
}
.movie-cash-showtime-selection-wrapper ul.showtime--list > li .showtime-wrap .qt-sold-out {
  font-family: "FFMarkWebPro", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1em;
  background: #FFF;
  border: solid 1px #A7A8AA;
  width: 80px;
  height: 2.5em !important;
  position: relative;
  display: block;
  float: left;
  cursor: default;
  padding: 2px 0;
}
.movie-cash-showtime-selection-wrapper ul.showtime--list > li .showtime-wrap .qt-sold-out .qt-showtime {
  color: #A7A8AA;
  font-size: 1em;
  display: block;
  text-align: center;
}
.movie-cash-showtime-selection-wrapper ul.showtime--list > li .showtime-wrap .qt-sold-out .qt-sold-out-text {
  position: absolute;
  bottom: 2px;
  left: 0;
  width: 100%;
  color: #d0021b;
  font-size: .6em;
  line-height: 1em;
  display: block;
  text-align: center;
  font-weight: bold;
}
.movie-cash-showtime-selection-wrapper ul.showtime--list > li .showtime-wrap .qt-sold-out .qt-showtime-day {
  display: none;
}
.movie-cash-error {
  position: absolute;
  top: 100%;
  left: -1px;
  right: -1px;
  background: #d0021b;
  border: 1px solid #d0021b;
  overflow: visible;
  z-index: 2;
  padding: 10px;
  margin-top: 9px;
  color: #FFF;
  font-weight: 700;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  text-align: left;
  margin-top: -2px;
}
.movie-cash-error .error-top-arrow {
  position: absolute;
  bottom: 100%;
  left: 20%;
  margin-left: -10px;
  border: 10px solid transparent;
  border-bottom: 10px solid #d0021b;
}
.movie-cash-error .error-top-arrow:before {
  content: "";
  position: absolute;
  top: -9px;
  left: -10px;
  border: 10px solid transparent;
  border-bottom: 10px solid #d0021b;
}
/* ==========================================================================
   Remodal necessary styles
   ========================================================================== */
/* Hide scroll bar */
html.remodal_lock,
body.remodal_lock {
  overflow: hidden;
}
/* Anti FOUC */
.remodal,
[data-remodal-id] {
  visibility: hidden;
}
/* Overlay necessary styles */
.remodal-overlay {
  position: fixed;
  z-index: 999999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  overflow: auto;
  text-align: center;
  -webkit-overflow-scrolling: touch;
}
.remodal-overlay .remodal-body {
  height: 80vh;
}
.remodal-overlay .remodal-body .seatMapWindowUrl {
  height: 100%;
  width: 100%;
}
.remodal-overlay:after {
  display: inline-block;
  height: 100%;
  margin-left: -0.05em;
  content: "";
}
/* Fix iPad, iPhone glitches */
.remodal-overlay {
  -webkit-backface-visibility: hidden;
}
/* Modal dialog necessary styles */
.remodal {
  position: relative;
  display: inline-block;
}
/* Remodal anti-zoom. Font-size should be >= 16px. */
.remodal select,
.remodal textarea,
.remodal input,
.remodal select:focus,
.remodal textarea:focus,
.remodal input:focus {
  font-size: 16px;
}
/* ==========================================================================
   Remodal default theme
   ========================================================================== */
/* Default theme font */
.remodal,
.remodal * {
  font-family: "Exo 2", sans-serif;
}
/* Background for effects */
.remodal-bg {
  -webkit-transition: -webkit-filter 0.1s linear;
  -moz-transition: -moz-filter 0.1s linear;
  -o-transition: -o-filter 0.1s linear;
  transition: filter 0.1s linear;
}
/* Overlay default theme styles */
.remodal-overlay {
  -webkit-transition: opacity 0.1s linear;
  -moz-transition: opacity 0.1s linear;
  -o-transition: opacity 0.1s linear;
  transition: opacity 0.1s linear;
  opacity: 0;
  background: rgba(33, 36, 46, 0.95);
}
body.remodal_active .remodal-overlay {
  opacity: 1;
}
/* Modal dialog default theme styles */
.remodal {
  font-size: 16px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  padding: 35px;
  padding: 35px 15px 15px;
  -webkit-transition: -webkit-transform 0.1s linear;
  -moz-transition: -moz-transform 0.1s linear;
  -o-transition: -o-transform 0.1s linear;
  transition: transform 0.1s linear;
  -webkit-transform: scale(0.95);
  -moz-transform: scale(0.95);
  -ms-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95);
  color: #182a3c;
  background: #f4f4f4;
  background-clip: padding-box;
  -webkit-box-shadow: 0 0 8px #171a24;
  box-shadow: 0 0 8px #171a24;
}
body.remodal_active .remodal {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
/* Modal dialog vertical align  */
.remodal,
.remodal-overlay:after {
  vertical-align: middle;
}
/* Close button */
.remodal-close {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 28px;
  height: 28px;
  -webkit-transition: background 0.1s linear;
  -moz-transition: background 0.1s linear;
  -o-transition: background 0.1s linear;
  transition: background 0.1s linear;
  text-decoration: none;
  border: 2px solid #3e5368;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.remodal-close:hover,
.remodal-close:focus {
  text-decoration: none;
}
.remodal-close:after {
  font-family: Arial, "Helvetica CY", "Nimbus Sans L", sans-serif !important;
  font-size: 28px;
  line-height: 25px;
  display: block;
  content: "×";
  cursor: pointer;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
  text-decoration: none;
  color: #3e5368;
}
.remodal-close:hover,
.remodal-close:active {
  background: #3e5368;
}
.remodal-close:hover.remodal-close:after,
.remodal-close:active.remodal-close:after {
  color: #f4f4f4;
}
/* Dialog buttons */
.remodal-confirm,
.remodal-cancel {
  font-size: 10pt;
  display: inline-block;
  width: 120px;
  margin: 0 0 5px 0;
  padding: 9px 0;
  cursor: pointer;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
  text-align: center;
  text-decoration: none;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  background-clip: padding-box;
}
.remodal-confirm {
  color: #16a085;
  border: 2px solid #16a085;
  background: #f4f4f4;
}
.remodal-confirm:hover,
.remodal-confirm:active {
  color: #f4f4f4;
  background: #16a085;
}
.remodal-cancel {
  color: #c0392b;
  border: 2px solid #c0392b;
  background: #f4f4f4;
}
.remodal-cancel:hover,
.remodal-cancel:active {
  color: #f4f4f4;
  background: #c0392b;
}
/* Media queries
   ========================================================================== */
/* min-width 641px */
@media only screen and (min-width: 40.063em) {
  .remodal {
    max-width: 700px;
    min-height: 0;
    margin: 20px auto;
    -webkit-border-radius: 6px;
    border-radius: 6px;
  }
}
/* IE8
   ========================================================================== */
html.lt-ie9,
html.lt-ie9 body {
  overflow: auto !important;
  min-height: 100%;
  margin: 0;
}
.lt-ie9 .remodal-overlay {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAQMAAAC3R49OAAAAA3NCSVQICAjb4U/gAAAABlBMVEX///8AAABVwtN+AAAAAnRSTlMAuyogpzwAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAGHRFWHRDcmVhdGlvbiBUaW1lADA2LzEwLzIwMTSCx1nsAAAAD0lEQVQImWP4//8DAxUxACnDOpkfX95WAAAAAElFTkSuQmCC);
}
.lt-ie9 .remodal-close:after {
  content: "";
}
.lt-ie9 .remodal {
  width: 500px;
  min-height: auto;
}
.movie-ratings-table {
  /*overflow: hidden;*/
  background: #f4f4f4;
  padding: 3rem 15px 15px;
  position: static;
}
@media (min-width: 992px) {
  .movie-ratings-table {
    padding-top: 2.5rem;
  }
}
.movie-ratings-table .modal-close {
  position: absolute;
  top: 0;
  right: 0;
  color: #000;
  font-size: 1.5rem;
}
@media (min-width: 992px) {
  .movie-ratings-table .modal-close {
    font-size: 1rem;
  }
}
.movie-ratings-table .row {
  border-top: 1px solid #d9d9d9;
  padding: 6px 0;
}
.movie-ratings-table .row:first-child {
  border-top: 0px solid #000;
}
.movie-ratings-table .ratings-table {
  background: #f4f4f4;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 0 15px;
}
.movie-ratings-table .heading-row {
  line-height: 27px;
  font-size: 1rem;
  font-weight: 700;
  background-color: #e6e6e6;
}
@media (min-width: 480px) {
  .movie-ratings-table .heading-row {
    font-size: 1rem;
  }
}
.movie-ratings-table .popover {
  transform: translateX(-50%);
  left: 50% !important;
  right: auto !important;
  width: 200px;
  max-width: 200px;
  text-align: center;
}
/*PAGE SPECIFIC STYLES*/
.accessibility-icons a:hover,
.accessibility-icons a:focus {
  text-decoration: none;
  color: #909090;
}
.rbo-wrapper {
  margin-left: -5px;
  margin-right: -5px;
}
.rbo-wrapper .rbo-item {
  padding: 0 5px;
  display: block;
  float: left;
  width: 50%;
  text-align: center;
}
/*@media (min-width: 768px) {
  .rbo-wrapper .rbo-item {
    width: 25%;
  }
}
@media (min-width: 992px) {
  .rbo-wrapper .rbo-item {
    width: 20%;
  }
}*/
.rbo-wrapper .rbo-item a {
  width: 100%;
  padding: 5px;
  text-align: center;
}
.rbo-wrapper .rbo-item a img {
  display: inline-block;
  max-height: 47px;
  max-width: 100%;
}
.theatre-details-map-iframe {
  height: 300px;
}
.theatre-admission-pricing-wrap .format-price-table thead td {
  border-top: none;
  padding-top: 0px;
  padding-bottom: 0px;
  vertical-align: top;
  height: auto;
  background: #FFF;
}
.theatre-admission-pricing-wrap .format-price-table thead td.age-groups {
  padding-top: 10px;
  padding-bottom: 0px;
}
.theatre-admission-pricing-wrap .format-price-table thead td.age-ranges {
  padding-top: 0px;
  padding-bottom: 10px;
}
.md-qt-widget-container {
  width: 100%;
  position: relative;
  float: left;
  /*Quick Ticket Styles*/
  /* Styles for Time Selection Pane */
}
@media (min-width: 768px) {
  .md-qt-widget-container {
    position: absolute;
    width: 50%;
    bottom: 15px;
    right: 0;
    height: calc(77.5%);
  }
}
@media (min-width: 992px) {
  .md-qt-widget-container {
    width: 0;
    position: static;
    height: auto;
  }
}
.md-qt-widget-container.no-qt {
  display: block;
  position: relative;
  width: 100%;
  padding: 0 15px;
}
@media (min-width: 768px) {
  .md-qt-widget-container.no-qt {
    position: absolute;
    bottom: 15px;
    width: 50%;
    top: 64px;
  }
}
@media (min-width: 992px) {
  .md-qt-widget-container.no-qt {
    top: 0;
    bottom: 0;
    width: 33.33333333%;
  }
}
@media (min-width: 768px) {
  .md-qt-widget-container.no-trailer #quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane .quick-tickets-header {
    padding-bottom: 10px;
  }
}
@media (min-width: 992px) {
  .md-qt-widget-container.no-trailer #quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane .quick-tickets-header {
    padding-bottom: 30px;
  }
}
.md-qt-widget-container .no-qt-html-area {
  margin-top: 30px;
  max-height: 306px;
}
@media (min-width: 768px) {
  .md-qt-widget-container .no-qt-html-area {
    margin-top: 0;
    max-height: none;
    max-height: unset;
  }
}
.md-qt-widget-container #quick-tickets-wrapper {
  min-height: 0;
  margin-top: 30px;
}
@media (min-width: 768px) {
  .md-qt-widget-container #quick-tickets-wrapper {
    margin-left: 0px;
    margin-top: 0px;
    float: left;
    position: relative;
    height: 100%;
  }
}
@media (min-width: 992px) {
  .md-qt-widget-container #quick-tickets-wrapper {
    height: 23.28571429em;
  }
}
@media (min-width: 992px) {
  .md-qt-widget-container #quick-tickets-wrapper.one-third-right {
    position: absolute;
    width: 33.33333333%;
    left: 66.66666666%;
    margin-left: 0px;
    margin-top: 0px;
    float: left;
    height: 100%;
  }
}
.md-qt-widget-container #quick-tickets-wrapper #quick-tickets-search {
  /* Styles for Search Pane */
}
@media (min-width: 768px) {
  .md-qt-widget-container #quick-tickets-wrapper #quick-tickets-search {
    height: 100%;
    z-index: 82;
  }
}
@media (min-width: 768px) {
  .md-qt-widget-container #quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane {
    background-color: #FFF;
    height: 100%;
    z-index: 83;
  }
}
@media (min-width: 768px) {
  .md-qt-widget-container #quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane.move {
    left: -100%;
    border-right: 1px solid #A7A8AA;
  }
}
@media (min-width: 768px) {
  .md-qt-widget-container #quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane.move .single.single-tabbed.quick-tickets-body {
    border-right: none;
  }
}
@media (min-width: 768px) {
  .md-qt-widget-container #quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane.qt-fixed-options {
    height: 100%;
  }
}
.md-qt-widget-container #quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane.qt-fixed-options .single.single-tabbed.quick-tickets-body {
  padding-bottom: 10px;
}
@media (min-width: 768px) {
  .md-qt-widget-container #quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane.qt-fixed-options .single.single-tabbed.quick-tickets-body {
    padding-bottom: 0;
  }
}
@media (max-width: 767px) {
  .md-qt-widget-container #quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane.qt-fixed-options .single.single-tabbed.quick-tickets-body .quick-tickets-selection-area {
    float: none;
    margin-left: 15px;
    margin-right: 15px;
    width: auto;
  }
}
.md-qt-widget-container #quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane .quick-tickets-header {
  padding-bottom: 10px;
}
@media (min-width: 768px) {
  .md-qt-widget-container #quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane .quick-tickets-header {
    padding-top: 15px;
    padding-bottom: 30px;
  }
}
@media (min-width: 768px) {
  .md-qt-widget-container #quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane .quick-tickets-header h2 {
    font-size: 1.5em;
  }
}
@media (min-width: 960px) {
  .md-qt-widget-container #quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane .quick-tickets-header h2 {
    font-size: 1.71429em;
    padding-right: 2em;
  }
}
@media (min-width: 768px) {
  .md-qt-widget-container #quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane .quick-tickets-header h2 .md-tooltip {
    padding: 0.25em;
    top: 2px;
    height: 24px;
    width: 24px;
  }
}
@media (min-width: 768px) {
  .md-qt-widget-container #quick-tickets-wrapper #quick-tickets-search #quick-tickets-search-pane .quick-tickets-header h2 .md-tooltip .info-icon {
    left: 4px;
    top: -4px;
  }
}
@media (min-width: 768px) {
  .md-qt-widget-container #quick-tickets-wrapper #quick-tickets-search .quick-tickets-content-wrap {
    bottom: 0;
    left: 15px;
    z-index: 2;
    top: unset;
    box-shadow: none;
    position: absolute;
    width: auto;
  }
}
@media (min-width: 768px) {
  .md-qt-widget-container #quick-tickets-wrapper #quick-tickets-search .quick-tickets-content-wrap .quick-tickets-nav ul li a {
    font-size: 0.9em;
  }
}
@media (min-width: 875px) {
  .md-qt-widget-container #quick-tickets-wrapper #quick-tickets-search .quick-tickets-content-wrap .quick-tickets-nav ul li a {
    font-size: 1.071428571428571em;
  }
}
@media (min-width: 768px) {
  .md-qt-widget-container #quick-tickets-wrapper #quick-tickets-search .quick-tickets-content-wrap .quick-tickets-nav ul li a:hover {
    background: #003c71;
  }
}
@media (min-width: 768px) {
  .md-qt-widget-container #quick-tickets-wrapper #quick-tickets-search .quick-tickets-content-wrap .quick-tickets-dropdown-wrap {
    padding: 20px 0 0;
  }
}
@media (min-width: 992px) {
  .md-qt-widget-container #quick-tickets-wrapper #quick-tickets-search .quick-tickets-content-wrap .quick-tickets-dropdown-wrap {
    padding: 20px 15px;
  }
}
@media (min-width: 768px) {
  .md-qt-widget-container #quick-tickets-wrapper .error-border .single.single-tabbed.quick-tickets-body {
    border-bottom: 1px solid #A7A8AA;
  }
}
@media (min-width: 768px) {
  .md-qt-widget-container #quick-tickets-wrapper .quick-tickets-loading-animation.loader {
    z-index: 1;
  }
}
@media (min-width: 768px) {
  .md-qt-widget-container .quick-tickets-selection-area {
    vertical-align: middle;
    width: 100%;
    margin-left: 0%;
  }
  .md-qt-widget-container .quick-tickets-selection-area:last-child {
    margin-bottom: 0px;
  }
}
@media (min-width: 768px) {
  .md-qt-widget-container #quicktickets-showtime-selection-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 1px;
    width: auto;
    margin-left: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    -moz-tranform: scaleY(1);
    -webkit-transform: scaleY(1);
    -o-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    border-top: 1px solid #A7A8AA;
    border-right: 1px solid #A7A8AA;
    border-bottom: 1px solid #A7A8AA;
    border-left: none;
    /*padding: 15px;*/
  }
}
@media (min-width: 768px) {
  .md-qt-widget-container #quicktickets-showtime-selection-wrapper.expand .quick-tickets-btn-wrap {
    background: rgba(255, 255, 255, 0);
    padding: 0 15px 10px;
    margin: 0;
  }
}
@media (min-width: 768px) {
  .md-qt-widget-container #quicktickets-showtime-selection-wrapper .btn-series {
    margin: 0px 15px 5px;
  }
}
@media (min-width: 768px) {
  .md-qt-widget-container #quicktickets-showtime-selection-wrapper .btn-series .qt-btn-series {
    font-size: 0.68em;
  }
}
@media (min-width: 768px) {
  .md-qt-widget-container #quicktickets-showtime-selection-wrapper .quick-tickets-mobile-select-prompt {
    padding-top: 20px;
  }
}
@media (min-width: 768px) {
  .md-qt-widget-container #quicktickets-showtime-selection-wrapper .showtime-grid ul.showtime--list {
    text-align: left;
  }
}
@media (min-width: 768px) {
  .md-qt-widget-container #quicktickets-showtime-selection-wrapper .showtime-grid ul.showtime--list > li {
    display: block;
    float: left;
    width: auto;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .md-qt-widget-container #quicktickets-showtime-selection-wrapper .showtimes-tab-error {
    background: rgba(0, 0, 0, 0);
    display: block;
    width: 100%;
    height: 22.57142858em;
    margin: 0;
    padding: 20px 10px;
    padding-bottom: 0;
  }
}
@media (min-width: 768px) {
  .md-qt-widget-container #quicktickets-showtime-selection-wrapper .showtimes-tab-error .tab-error-content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    -moz-tranform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
@media (max-width: 767px) {
  .md-qt-widget-container #quicktickets-showtime-selection-wrapper .ngsb-wrap {
    height: auto !important;
  }
}
@media (max-width: 767px) {
  .md-qt-widget-container #quicktickets-showtime-selection-wrapper .ngsb-scrollbar {
    display: none;
  }
}
.md-ft-area {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .md-ft-area {
    margin-bottom: 40px;
  }
}
.md-ft-area .md-image-and-info-wrap {
  float: left;
  position: relative;
}
@media (min-width: 768px) {
  .md-ft-area .md-image-and-info-wrap {
    margin-bottom: 15px;
  }
}
@media (min-width: 992px) {
  .md-ft-area .md-image-and-info-wrap {
    float: none;
    margin-bottom: 15px;
  }
}
.md-ft-area .movie-specs-text-wrap {
  position: static;
}
.md-ft-area .movie-specs-text {
  font-size: 0.85714285714285714285714285714286em;
}
.md-ft-area .md-film-title {
  padding-left: 0;
}
@media (min-width: 768px) {
  .md-ft-area .md-film-title {
    white-space: nowrap;
  }
}
@media (min-width: 992px) {
  .md-ft-area .md-film-title {
    padding-left: 15px;
    white-space: normal;
  }
}
.md-ft-area .md-film-title h1 {
  margin-top: 10px;
  margin-bottom: 25px;
}
@media (min-width: 768px) {
  .md-ft-area .md-film-title h1 {
    margin-top: 0px;
  }
}
@media (min-width: 992px) {
  .md-ft-area .md-film-title h1 {
    margin-bottom: 10px;
  }
}
.md-ft-area .md-header-poster {
  padding-right: 0px;
}
@media (min-width: 992px) {
  .md-ft-area .md-header-poster {
    padding-right: 20px;
  }
}
.md-ft-area .md-header-poster .md-bookmark {
  font-weight: 700;
  font-size: 36px;
  padding: 5px;
  right: 0px;
  text-decoration: none;
  color: #FFF;
}
@media (min-width: 992px) {
  .md-ft-area .md-header-poster .md-bookmark {
    padding: 10px;
    right: 20px;
  }
}
.md-ft-area .md-header-poster .md-bookmark .bookmark-symbol {
  font-size: 10px;
  top: 13px;
  left: 14px;
}
@media (min-width: 992px) {
  .md-ft-area .md-header-poster .md-bookmark .bookmark-symbol {
    top: 18px;
    left: 19px;
  }
}
.md-ft-area .md-header-poster .md-bookmark .fa-plus {
  display: block;
}
.md-ft-area .md-header-poster .md-bookmark .fa-minus {
  display: none;
}
.md-ft-area .md-movie-length {
  display: block;
}
@media (min-width: 480px) {
  .md-ft-area .md-movie-length {
    display: inline-block;
  }
}
.md-ft-area .md-ratings-warnings-link {
  vertical-align: top;
}
.md-ft-area .md-ratings-warnings-link .fa-info-circle {
  font-size: 1.5em;
}
.md-ft-area .md-critics-ratings-area {
  bottom: 0;
  margin-bottom: 0;
  width: auto;
  white-space: nowrap;
}
@media (min-width: 992px) {
  .md-ft-area .md-critics-ratings-area {
    position: static;
    width: 100%;
    margin-bottom: 10px;
  }
}
.md-ft-area .md-critics-ratings-area .md-critic-rating-wrap {
  display: block;
  float: left;
  width: auto;
  padding-bottom: 0px;
  padding-right: 5px;
  color: #0A0A0A;
  font-size: 0.85714285714285714285714285714286em;
  text-decoration: none;
}
@media (min-width: 380px) {
  .md-ft-area .md-critics-ratings-area .md-critic-rating-wrap {
    padding-right: 1em;
    font-size: 1em;
  }
}
.md-ft-area .md-critics-ratings-area .md-critic-rating-wrap:hover {
  text-decoration: none;
}
.md-ft-area .md-critics-ratings-area .md-critic-rating-wrap + .md-critic-rating-wrap {
  padding-right: 0;
  padding-left: 5px;
  border-left: 1px solid #909090;
}
@media (min-width: 380px) {
  .md-ft-area .md-critics-ratings-area .md-critic-rating-wrap + .md-critic-rating-wrap {
    padding-left: 1em;
  }
}
.md-ft-area .md-critics-ratings-area .md-critic-logo {
  padding-right: 5px;
  height: 20px;
}
@media (min-width: 380px) {
  .md-ft-area .md-critics-ratings-area .md-critic-logo {
    height: 20px;
    padding-right: 1em;
  }
}
.md-ft-area .md-critics-ratings-area .md-critic-rating {
  font-weight: 700;
}
.md-ft-area .md-trailer-section {
  padding: 30px 0 0;
}

@media (min-width: 375px) {
  .md-ft-area .md-trailer-section {
    padding: 15px 0px 0px;
    margin-bottom: -10px;
  }
}

@media (min-width: 1024px) {
    .md-ft-area .md-trailer-section {
        margin-bottom: 0;
    }
}

.md-ft-area .md-trailer-section .md-trailer-btn {
  overflow: visible;
  padding: 15px 12px;
  color:#004ccc !important;
}
@media (min-width: 992px) {
  .md-ft-area .md-trailer-section .md-trailer-btn {
    padding: 6px 12px;
  }
}
.md-ft-area .md-trailer-section .md-trailer-btn:focus,
.md-ft-area .md-trailer-section .md-trailer-btn:hover {
    color: #004ccc !important;
}
.md-ft-area .md-trailer-section .md-trailer-btn .fa-play-circle {
  position: relative;
  top: -0.3em;
  height: 1em;
  font-size: 1.5em;
  line-height: 1.5em;
  overflow: visible;
}
@media (min-width: 992px) {
  .md-ft-area .md-trailer-section .md-trailer-btn .fa-play-circle {
    top: -0.25em;
  }
}
.md-ft-area .md-bottom-border {
  border-bottom: 0px solid #000;
  margin: 0 15px;
}
@media (min-width: 768px) {
  .md-ft-area .md-bottom-border {
    border-bottom: 1px solid #A7A8AA;
  }
}
@media (min-width: 992px) {
  .md-ft-area .md-bottom-border {
    border-bottom: 0px solid #000;
  }
}
.md-body-wrap .md-info-body {
  margin-left: 0.2em;
  font-size: 1em;
}
@media (min-width: 992px) {
  .md-body-wrap .md-social-media-widget-area {
    margin-top: 60px;
  }
}
.cplx-gallery-wrapper {
  position: relative;
  margin-bottom: 5px;
}
@media (min-width: 768px) {
  .cplx-gallery-wrapper {
    margin-bottom: 15px;
  }
}
.cplx-gallery-wrapper .md-preview-img-wrapper {
  -js-display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media (min-width: 768px) {
  .cplx-gallery-wrapper .md-preview-img-wrapper {
    -webkit-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
.cplx-gallery-wrapper .md-preview-img-wrapper modal-window {
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -ms-flex: 1 0 0%;
  text-align: right;
}
.cplx-gallery-wrapper .md-preview-img-wrapper modal-window:first-child {
  text-align: left;
}
.cplx-gallery-wrapper .md-preview-img-wrapper modal-window .cta-primary {
  max-width: 100%;
  display: inline-block;
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
}
.cplx-gallery-wrapper .md-preview-img-wrapper .md-gallery-img {
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  max-height: 300px;
  display: inline-block;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .cplx-gallery-wrapper .md-preview-img-wrapper .md-gallery-img {
    margin-bottom: 0;
  }
}
.cplx-gallery-wrapper .cplx-lightbox-link {
  clear: both;
  position: relative;
  padding-top: 0px;
  display: block;
}
@media (min-width: 768px) {
  .cplx-gallery-wrapper .cplx-lightbox-link {
    position: absolute;
    top: 0;
    right: 15px;
    padding-top: 0.25em;
  }
}
.movie-showtimes-table-wrapper.showtimes-hidden {
  visibility: hidden;
}
.movie-showtimes-table-wrapper .dropdown-toggle {
  padding: 10px 12px;
}
.movie-showtimes-table-wrapper .date-movie-conjunction {
  font-size: 1.1428571428571428571428571428571em;
  padding: 10px 20px;
  margin: 20px 0 0;
  vertical-align: top;
}
@media (min-width: 768px) {
  .movie-showtimes-table-wrapper .date-movie-conjunction {
    margin: 40px 0 0;
    padding: 10px 10px;
  }
}
.movie-showtimes-table-wrapper .date-selection-dropdown {
  display: inline-block;
  width: 13rem;
  max-width: 100%;
  padding-bottom: 0px;
  margin: 20px 0 0;
}
@media (min-width: 768px) {
  .movie-showtimes-table-wrapper .date-selection-dropdown {
    margin: 40px 0 0;
  }
}
.movie-showtimes-table-wrapper .date-selection-dropdown .dropdown-menu {
  overflow-y: auto;
  max-height: 400px;
  padding-bottom: 0px;
  border: 1px solid #004ea8;
}
.movie-showtimes-table-wrapper .date-selection-dropdown .dropdown-menu > li > a {
  position: relative;
  border-bottom: 1px solid #0A0A0A;
  color: #0A0A0A;
  padding: 0.7em 0.5em;
  text-align: center;
}
.movie-showtimes-table-wrapper .md-selected-date {
  height: 1.25em;
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}
.movie-showtimes-table-wrapper .selectize-input-wrap {
  position: relative;
  display: inline-block;
  width: 20rem;
  max-width: 100%;
  vertical-align: top;
  margin: 20px 0 0;
}
@media (min-width: 768px) {
  .movie-showtimes-table-wrapper .selectize-input-wrap {
    margin: 40px 0 0;
  }
}
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-control {
  text-align: left;
  color: #FFF;
  font-weight: 400;
  height: auto;
  line-height: 1.42857143;
  z-index: 1;
}
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-control .selectize-input::after {
  display: none;
}
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-control .selectize-input,
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-control .selectize-input.dropdown-active {
    border-radius: 0;
    padding: 10px 12px;
    padding-right: 2em;
    font-weight: 400;
    color: #FFF;
    height: 2.5em;
    line-height: 1.25em;
    background-color: #004ea8;
}
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-control .selectize-input > input,
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-control .selectize-input.dropdown-active > input {
  color: #fff;
  position: absolute !important;
  width: 100%;
  top: 0;
  left: 0;
  padding: 10px 12px !important;
  padding-right: 2em !important;
}
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-control .selectize-input ::-webkit-input-placeholder,
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-control .selectize-input.dropdown-active ::-webkit-input-placeholder {
  font-weight: 400;
  color: #F5F5F5;
}
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-control .selectize-input :-moz-placeholder,
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-control .selectize-input.dropdown-active :-moz-placeholder {
  /* Firefox 18- */
  font-weight: 400;
  color: #F5F5F5;
}
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-control .selectize-input ::-moz-placeholder,
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-control .selectize-input.dropdown-active ::-moz-placeholder {
  /* Firefox 19+ */
  font-weight: 400;
  color: #F5F5F5;
}
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-control .selectize-input :-ms-input-placeholder,
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-control .selectize-input.dropdown-active :-ms-input-placeholder {
  font-weight: 400;
  color: #F5F5F5;
}
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-control .selectize-input.input-active,
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-control .selectize-input.dropdown-active.input-active {
  color: #FFF;
  background: #004ea8;
}
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-control .selectize-input .item,
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-control .selectize-input.dropdown-active .item {
  max-width: 100%;
  font-weight: 400;
  line-height: 1.25em;
}
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-control.selectize-open .selectize-input.input-active {
  background-color: #003c71;
}
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-control.selectize-open .selectize-input.input-active ::-webkit-input-placeholder {
  color: #F5F5F5;
}
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-control.selectize-open .selectize-input.input-active :-moz-placeholder {
  /* Firefox 18- */
  color: #F5F5F5;
}
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-control.selectize-open .selectize-input.input-active ::-moz-placeholder {
  /* Firefox 19+ */
  color: #F5F5F5;
}
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-control.selectize-open .selectize-input.input-active :-ms-input-placeholder {
  color: #F5F5F5;
}
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-dropdown {
  background: #FFF;
  border: 1px solid #004ea8;
  left: 0 !important;
  right: 0 !important;
}
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-dropdown .selectize-dropdown-content .optgroup:before {
  border-bottom: 1px solid #004ea8;
}
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-dropdown .selectize-dropdown-content .optgroup .optgroup-header {
  color: #004ea8;
  background: #FFF;
}
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-dropdown .selectize-dropdown-content .optgroup .option {
  border-bottom: 1px solid #0A0A0A;
  color: #0A0A0A;
}
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-dropdown .selectize-dropdown-content .optgroup .option:last-child {
  border-bottom: 0;
}
.movie-showtimes-table-wrapper .selectize-input-wrap .selectize-open ~ .right-icon-wrap {
  z-index: 2;
}
.movie-showtimes-table-wrapper .right-icon-wrap {
  font-size: 0.914285714288em;
  right: 5px;
  top: -3px;
  position: absolute;
  text-align: center;
  vertical-align: middle;
  height: 100%;
  width: 2em;
  z-index: 1;
  color: #FFF;
}
.movie-showtimes-table-wrapper .right-icon-wrap i {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.movie-showtimes-table-wrapper .movie-showtimes-row {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .movie-showtimes-table-wrapper .movie-showtimes-row {
    margin-top: 40px;
  }
}

.movie-showtimes-table-wrapper .movie-showtimes-row .movie-showtimes-section {
    padding: 0px;
}

    .movie-showtimes-row .movie-showtimes-section .movie-showtimes-section-info {
        display: flex;
    }

        .movie-showtimes-row .movie-showtimes-section .movie-showtimes-section-info .tdp-mobile-trailer-link {
            position: absolute;
            bottom: 0;
        }

        .movie-showtimes-section-info picture img:first-child{
            min-height: 180px;
        }

.theatre-details-mpaa {
    width: 20px;
    height: 20px;
}

@media (min-width: 768px) {
    .movie-showtimes-table-wrapper .showtime-grid {
    /*width: calc(100% - 280px);*/
    float: left;
  }
}
.movie-showtimes-table-wrapper .showtime-grid ul.showtime--list {
  margin: 0;
}
@media (min-width: 768px) {
  .movie-showtimes-table-wrapper .showtime-grid ul.showtime--list {
    margin: 0px;
  }
}
.movie-showtimes-table-wrapper .showtime-grid ul.showtime--list > li {
  margin-bottom: 10px;
}
.movie-showtimes-table-wrapper .banner-sprite-area {
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .movie-showtimes-table-wrapper .banner-sprite-area {
    margin-bottom: 0;
    width: 280px;
    float: left;
  }
  .movie-showtimes-table-wrapper .banner-sprite-area .banner-sprite {
    padding: 0 5px;
  }
}

.experience-banner {
    padding: 0 0 10px 0;
}

.movie-showtimes-table-wrapper .banner-sprite-area .banner-text,
.movie-showtimes-table-wrapper .banner-sprite-area .presentation-type {
    padding: 5px 0px;
    text-align: right;
    font-size: .75rem;
}

.news-img-load {
    position: relative;
    width: 100%;
    min-height: 400px;
    text-align: center;
}

img.default-transparent-image {
    background-image: url(../../img/lazy_load_transparent.png);
}

img.default-image {
    background-image: url(../../img/lazy_load_default.png);
}

.theatre-detail-toggle-btn {
    display: none;
    color: #004ea8;
    font-weight: 600;
    font-size: 0.929em;
    text-align: left;
}

.theatre-detail-toggle-btn.collapsed {
    display: block;
    color: #004ea8;
}

#theatre-details-info {
    visibility: hidden;
}

#theatre-details-info.in {
    visibility: visible;
}

.tdp-showtime-session-table {
    background-color: #004ea80d;
}

.theatre-movie-title {
    font-size: 1.2857em;
}

.theatre-movie-poster-wrapper {
    margin-top: 25px;
}

.theatre-movie-no-pass {
    font-size: 0.929em;
    font-weight: 300;
    color: #202733;
    text-transform: capitalize;
    vertical-align: bottom;
}

.theatre-showtime-selection-wrapper {
    margin-bottom: 15px;
}

@media (min-width: 768px) {
    .theatre-movie-poster-wrapper {
        margin-top: 10px;
    }

    .theatre-showtime-selection-wrapper {
        margin-top: 10px;
    }
}

.theatre-showtime-selection-wrapper + div .border-bottom-silver {
    border-bottom: 1px solid #d7d7d7;
}

.tdp-date-dropdown-wrapper::after {
    content: '\f078';
    font: normal normal normal 16px/1 FontAwesome;
    color: white;
    right: 30px;
    top: 5px;
    height: 34px;
    padding: 10px 0px 0px 0px;
    position: absolute;
    pointer-events: none;
}

#theatre-date-dropdown {
    height: 50px;
    font-weight: bold;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

#theatre-date-dropdown option {
    color: #004ea8;
    background-color: white;
}

.visually-hidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap; /* added line */
}
.menu-account .loginSignUp:last-child {
    display: none;
}

#theatre-details-wrap .theatre-experiences img {
    max-height: 18px;
}

#theatre-details-wrap .theatre-experiences span {
    font-size: 1.5em;
    vertical-align: middle;
}

#theatre-details-wrap .get-showtimes-section {
    margin-top: 30px;
    margin-bottom: 42px;
}

#theatre-details-wrap .get-showtimes-btn {
    display: block;
    background: #004ccc;
    color: #FFF;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    width: 100%;
    height: 36px;
    margin-top: 10px;
    padding: 8px;
}

@media (min-width: 1024px) {
  #theatre-details-wrap .get-showtimes-btn {
    width: 244px;
  }
}

.fixed-scroll {
    position: fixed;
    top: 0;
    z-index: 1002;
    width: 100%;
}

/*PciCompliance*/
#businessLogo {
    margin-top: -30px;
}

#buImg {
    margin-top: 20px;
    margin-left: -15px;
}

#pci-compliance-form input {
    width: 100%;
}

#pciValidationMessage {
    font-weight: 700 !important;
}

/* NextGen Styles */
.globalHeaderContainer {
    display: contents;
}

.skip-link {
    position: absolute;
    left: -1000px;
    top: 5px;
    z-index: 999;
    background: #0A0B0D;
    color: #1985FF;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    text-decoration: none;
    font-family: MarkOT, MarkOT-CondBlack, sans-serif;
}

    .skip-link:focus, .skip-link:focus-within, .skip-link:focus-visible {
        left: 0;
        text-decoration: none;
        color: #1985FF;
    }

@media (min-width: 600px ) {
    .skip-link {
        font-size: 16px;
        line-height: 24px;
    }
}

@media (min-width: 1920px ) {
    .skip-link {
        font-size: 20px;
        line-height: 28px;
    }
}


/* Default SVG Icon Colours*/
svg {
    color: #EEEEEE
}

svg[data-color] {
    color: attr(data-color)
}

svg[data-hoverable] {
    cursor: pointer
}

@media (hover: hover) {
    svg[data-hoverable]:hover {
        color: #1985FF
    }

    svg[data-hoverable]:hover[data-hover-color] {
        color: attr(data-hover-color)
    }
}

svg[data-hoverable]:active,
svg[data-hoverable]:focus {
    color: #1985FF
}

svg[data-hoverable]:active[data-hover-color],
svg[data-hoverable]:focus[data-hover-color] {
    color: attr(data-hover-color)
}