$arrow-active: #FFF;
$bullet-active: #FFF;
$arrow-background: transparent;
$arrow-active-bg: #003c71;
$bullet-fill: transparent;
$bullet-boarder: #FFF;
$mobile-screen: 468px;

.royalSlider {
    direction: ltr;
    position: relative;
    margin: 0 auto;
    width: 100%;

    > * {
        float: left;
    }

    &.rsHor {
        .rsArrow {
            &.rsArrowLeft {
                bottom: 0;
            }

            &.rsArrowRight {
                bottom: 0;
            }
        }

        &.royal-slider-one-third-text-two-thirds-image {
            .rsContainer {
                white-space: nowrap;

                .rsSlide {
                    white-space: normal;
                    display: table-cell;
                    padding: 0;
                    height: auto;
                    float: left;

                    .image-area {
                        position: relative;
                        float: left;
                        width: 100%;
                        text-align: right;

                        @media (min-width: 480px) {
                            left: 33.33333%;
                            width: 66.66666%;
                        }

                        a {
                            display: block;
                            float: right;
                        }
                    }

                    .text-area {
                        position: relative;
                        height: auto;
                        width: 100%;
                        float: right;
                        padding: 40px 20px 20px 20px;

                        @media (min-width: 480px) {
                            padding-top: 20px;
                            position: absolute;
                            left: 0;
                            top: 0;
                            bottom: 0;
                            width: 33.333333%;
                        }
                    }
                }
            }

            .rsArrow {
                @media (min-width: 480px) {
                    display: none !important;
                }

                &.rsArrowLeft {
                    left: 10px;
                    top: 170px;
                    -moz-transform: translateY(-50%);
                    -o-transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);

                    @media (min-width: 480px) {
                        top: 50%;
                        left: 34.3333333%;
                    }
                }

                &.rsArrowRight {
                    top: 170px;
                    right: 10px;
                    -moz-transform: translateY(-50%);
                    -o-transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);

                    @media (min-width: 480px) {
                        top: 50%;
                        right: 1%;
                    }
                }
            }

            .rsNav {
                position: absolute;
                top: 340px;
                left: 0;

                @media (min-width: 480px) {
                    position: static;
                    z-index: 0;
                }
            }

            &:hover {
                .rsArrow {
                    display: block !important;
                }
            }
        }
    }

    &.rsVer {
        .rsContainer {
            .rsSlide {
                padding: 20px 0;
            }
        }

        .rsArrow {
            &.rsArrowLeft {
                top: 0;
                left: 50%;
                -moz-transform: translateX(-50%);
                -o-transform: translateX(-50%);
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
            }

            &.rsArrowRight {
                bottom: 0;
                left: 50%;
                -moz-transform: translateX(-50%);
                -o-transform: translateX(-50%);
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
            }
        }
    }

    &.rsAutoHeight {
        height: auto;

        .rsOverflow {
            .rsContainer {
                .rsSlide {
                    height: auto;
                }
            }
        }
    }

    .rsOverflow {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        float: left;
        height: 100%;
        overflow: hidden;
        position: relative;
        width: 100%;

        .rsContainer {
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            height: 100%;
            position: relative;
            width: 100%;

            .rsSlide {
                display: block;
                height: 100%;
                left: 0;
                overflow: hidden;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }

        .rsArrow {
            position: absolute;
            display: block;
            font-family: 'FontAwesome';
            height: 20px;
            width: 20px;
            text-align: center;
            background: $arrow-background;
            color: $arrow-active;
            cursor: pointer;
            font-size: 1em;
            padding: 0.2em;
            margin: 12px;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            -webkit-border-radius: 50%;
            border-radius: 50%;

            @media(max-width: $mobile-screen) {
                margin: 4px;
            }

            &.rsArrowLeft {
                &:before {
                    position: relative;
                    left: -0.1em;
                    content: "\f053";
                }
            }

            &.rsArrowRight {
                :before {
                    position: relative;
                    right: -0.1em;
                    content: "\f054";
                }
            }
        }
    }

    .rsNav {
        height: 14px;
        margin-top: 12px;
        margin-bottom: 16px;
        text-align: center;

        @media(max-width: $mobile-screen) {
            margin-bottom: 8px;
        }

        .rsNavItem {

            &.rsBullet {
                background: rgba(0, 0, 0, 0);
                display: inline-block;
                padding: 0 16px 0 0;
                cursor: pointer;

                @media(max-width: $mobile-screen) {
                    padding: 0 12px 0 0;
                }

                &:hover, &:focus {
                    span {
                        background: $bullet-active;
                    }
                }

                &.rsNavSelected {
                    span {
                        background: $bullet-active;
                        cursor: default;
                    }
                }

                &:first-child {
                    -moz-border-radius: 5px 0 0 5px;
                    -ms-border-radius: 5px 0 0 5px;
                    -o-border-radius: 5px 0 0 5px;
                    -webkit-border-radius: 5px 0 0 5px;
                    border-radius: 5px 0 0 5px;
                }

                &:last-of-type {
                    -moz-border-radius: 0 5px 5px 0;
                    -ms-border-radius: 0 5px 5px 0;
                    -o-border-radius: 0 5px 5px 0;
                    -webkit-border-radius: 0 5px 5px 0;
                    border-radius: 0 5px 5px 0;
                    padding: 0;
                }

                span {
                    border: 1px solid $bullet-boarder;
                    background: $bullet-fill;
                    -moz-border-radius: 12px;
                    -ms-border-radius: 12px;
                    -o-border-radius: 12px;
                    -webkit-border-radius: 12px;
                    border-radius: 12px;
                    display: block;
                    height: 12px;
                    width: 12px;

                    @media(max-width: $mobile-screen) {
                        -moz-border-radius: 8px;
                        -ms-border-radius: 8px;
                        -o-border-radius: 8px;
                        -webkit-border-radius: 8px;
                        border-radius: 8px;
                        height: 8px;
                        width: 8px;
                    }
                }
            }
        }
    }

    &:hover {
        .rsOverflow {
            .rsArrow {
                opacity: 1;
            }
        }
    }

    &.overlay-nav {
        .rsNav {
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);

            .rsNavItem {
                &.rsBullet {
                }
            }
        }
    }
}