movies-grid, store-movies-grid, movies-grid-controls {
    min-height: 400px;
}

.featured-movies-grid-wrap {
    &.compact-display {
        .nav-tabs .movie-grid-tab a {
            font-family: "FFMarkWebPro", Helvetica, Arial, sans-serif;
            padding-left: 10px;
            padding-right: 10px;
            font-size: 0.8em;

            @media (min-width: 375px) {
                font-size: 1em;
            }
        }

        .dropdown.filter-dropdown {
            a.dropdown-toggle {
                font-family: "FFMarkWebPro", Helvetica, Arial, sans-serif;
                padding-left: 10px;
                padding-right: 10px;
            }

            @media (min-width: 970px) {
                min-width: 0;
                width: 9em;
            }
        }

        &.fr-ca {
            .nav-tabs .movie-grid-tab a {
                font-size: 0.8571428571428571em;
                font-family: "FFMarkWebPro", Helvetica, Arial, sans-serif;
                padding-left: 10px;
                padding-right: 10px;
                font-size: 0.5em;

                @media (min-width: 350px) {
                    font-size: 0.5625em;
                }

                @media (min-width: 415px) {
                    font-size: 0.71428571em;
                }

                @media (min-width: 490px) {
                    font-size: 0.8571428571428571em;
                }
            }

            .dropdown.filter-dropdown {
                a.dropdown-toggle {
                    font-size: 0.8571428571428571em;
                    font-family: "FFMarkWebPro", Helvetica, Arial, sans-serif;
                    padding-left: 10px;
                    padding-right: 10px;
                }

                @media (min-width: 970px) {
                    min-width: unset;
                    width: 8em;
                }
            }
        }
    }

    &.fr-ca {
        .nav-tabs .movie-grid-tab a {
            font-size: 0.5em;

            @media (min-width: 350px) {
                font-size: 0.5625em;
            }

            @media (min-width: 415px) {
                font-size: 0.71428571em;
            }

            @media (min-width: 490px) {
                font-size: 1em;
            }
        }

        .dropdown.filter-dropdown {
            @media (min-width: 350px) and (max-width: 969px) {
                top: 7em;
            }

            a.dropdown-toggle {
                @media (max-width: 490px) {
                    font-size: 0.71428571em;
                }
            }
        }
    }

    .nav-tabs {
        height: auto;
        overflow: hidden;
        border-bottom: 0px solid #000;

        .movie-grid-tab {
            width: 25%;

            a {
                font-family: 'FFMarkWebProBold', sans-serif;
                font-weight: 400;
                text-align: center;
                padding-left: 5px;
                padding-right: 5px;
                padding-bottom: 100px;
                margin-bottom: -90px;
                word-wrap: break-word;
                font-size: 0.8em;

                @media (min-width: 340px) {
                    font-size: 1em;
                }

                @media (min-width: 600px) {
                    padding-left: 15px;
                    padding-right: 15px;
                }

                @media (min-width: 970px) {
                    padding-left: 30px;
                    padding-right: 30px;
                    padding-bottom: 10px;
                    margin-bottom: 0;
                    font-size: 1em;
                    height: auto;
                }
            }

            @media (min-width: 970px) {
                width: auto;
                height: auto;
            }
        }

        @media (min-width: 992px) {
            border-bottom: 1px solid #a7a8aa;
        }

        @media (min-width: 970px) {
            height: auto;
            overflow: visible;
        }
    }

    .dropdown.filter-dropdown {
        position: absolute;
        top: 8em;
        left: 15px;
        right: 15px;

        @media (min-width: 520px) {
            top: 6em;
        }

        @media (min-width: 970px) {
            top: 9px;
            right: 15px;
            left: auto;
            left: initial;
            height: auto;
            width: 11.42857142857143em;
            min-width: 160px;
        }

        a.dropdown-toggle {
            font-family: 'FFMarkWebProBold', sans-serif;
            font-weight: 400;
            text-decoration: none;
            display: block;
            position: relative;
            padding: 10px 10px;
            line-height: 1.42857143;
            font-size: 1em;
            border: 1px solid rgba(0, 0, 0, 0);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @media (min-width: 970px) {
                padding: 10px 30px;
            }

            &:hover, &:focus {
                background: #003c71;
            }
        }

        .dropdown-menu {
            width: 100%;

            > li {
                position: relative;

                > a {
                    white-space: normal;
                    padding-left: 1em;
                    padding-right: 1em;

                    .fa {
                        position: absolute;
                        left: 0.75em;
                        top: 5px;
                    }
                }
            }
        }
    }

    .movies-grid.has-filters {
        margin-top: 6em;

        @media (min-width: 970px) {
            margin-top: 0;
        }
    }
}

.movies-grid {
    .grid-movie-item {
        padding-bottom: 40px;

        .movie-details-link {
            text-decoration: none;
            width: 100%;

            .movie-img-wrapper {
                display: inline-block;
                height: 0;
                width: 100%;
                position: relative;
                padding-bottom: 148.14814815%;
                outline: 1px solid #0004;
                outline-offset: -2px;

                .movie-aspect-ratio-holder {
                    display: table;
                    content: "";
                    width: 100%;
                    position: absolute;
                    height: 100%;

                    .movie-image-title {
                        display: table-cell;
                        vertical-align: middle;
                        text-align: center;
                        font-size: 16px;
                        padding: 8px;
                        color: black;
                    }
                }

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                }
            }

            .movie-item-title {
                height: 3em;
                margin: 5px 0 0;
                color: #0A0A0A;
                word-wrap: break-word;

                &:hover, &:active, &:selected {
                    color: #0A0A0A;
                }
            }
        }

        .grid-movie-links .grid-movie-item-subtext {
            font-size: 0.857143em;

            a {
                text-decoration: none;
                height: auto;
                display: inline-block;
            }

            .fa {
                font-size: 1.428571428571429em;
                overflow: visible;
                position: relative;
                top: 1px;
                width: 0.9em;
                height: 1em;
            }

            .store-link {
                &:hover .store-circle-icon {
                    background: #003c71;
                }

                .store-circle-icon {
                    display: inline-block;
                    position: relative;
                    top: 4px;
                    background: #004ea8;
                    color: #FFF;
                    height: 18px;
                    width: 18px;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;
                    -webkit-border-radius: 50%;
                    border-radius: 50%;

                    &:before {
                        position: absolute;
                        font-size: 11px;
                        top: 50%;
                        height: 12px;
                        width: 18px;
                        text-align: center;
                        -moz-transform: translateY(-50%);
                        -o-transform: translateY(-50%);
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                    }
                }
            }

            .trailer-link {
                color: #ff671f;

                &:hover, &:focus {
                    color: #9f3300;
                }
            }
        }
    }

    .show-more-pane {
        display: block;
        position: relative;

        @media (min-width: 992px) {
            width: 20%;
            padding-left: 15px;
            padding-right: 15px;
        }

        .show-more-pane-content {
            cursor: pointer;
            display: block;
            width: 100%;
            background: #004ea8;
            position: relative;

            &:before {
                display: block;
                content: "";
                width: 100%;
                padding-top: 148.14814815%;
            }

            > .content {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            &:hover, &:focus {
                background: #062951;
            }

            .show-more-pane-text {
                color: #FFF;
                font-size: 1.28571em;
                word-spacing: 99999px;

                @media (min-width: 992px) {
                    font-size: 1.142857142857143em;
                }

                .fa {
                    display: block;
                    font-size: 1.777778em;

                    @media (min-width: 992px) {
                        font-size: 2em;
                    }
                }
            }
        }
    }
}

.movies-init-load {
    position: relative;
    width: 100%;
    min-height: 400px;
    text-align: center;
    margin-bottom: 200px;

    @media (min-width: 480px) {
        min-height: 300px;
    }
}

.movies-grid-error {
    display: none;
}

.movie-grid-loading-block {
    display: block;
    min-height: 400px;
    width: 100%;
}